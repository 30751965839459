import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {GlobaProvider} from './global/globa-provider';
import firebase from './firebase/firebase';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === 'development'){
  if (process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN) {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
  }
  initializeAppCheck(firebase, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_FIREBASE_APP_CHECK_RECAPTCHA),
    isTokenAutoRefreshEnabled: true
  });
} else {
  initializeAppCheck(firebase, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_FIREBASE_APP_CHECK_RECAPTCHA),
    isTokenAutoRefreshEnabled: true
  });
}

root.render(
  <React.StrictMode>
    <GlobaProvider>
      <App />
    </GlobaProvider>
  </React.StrictMode>
);
