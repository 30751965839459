import loadingSpinner from '../../../../assets/icons/loaders/loading-spinner.svg'

const LoadingSpinner = () => {

    return (
        <div className="flex h-full w-full justify-center items-center">
            <img className="h-full" src={loadingSpinner}></img>
        </div>
    )
}

export default LoadingSpinner