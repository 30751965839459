import { Button, Modal } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

const RedirectModal = ({redirectModal, setRedirectModal}) => {

    const navigate = useNavigate()
    const location = useLocation()

    const onLoginClick = () => {
        navigate('/redirect', {state: { prevPath: location.pathname }})
    }

    return (
        <Modal
            open={redirectModal} 
            closeIcon={false}
            onCancel={() => setRedirectModal(false)}
            title="You are not logged in"
            maskClosable={false}
            footer={[
                <Button type="primary" onClick={onLoginClick}>
                    Log In
                </Button>,
                <Button type="default" onClick={() => navigate('/')}>
                    Back to home
                </Button>,
            ]}
        >
            You need to log in and have access to view this trip!
        </Modal>
    )
}

export default RedirectModal