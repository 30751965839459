import { collection, deleteDoc, deleteField, doc, getDoc, getDocs, query, setDoc, updateDoc, where, arrayRemove } from "firebase/firestore";
import firestore from "./firestore";
import { _fromLocalDate } from "../../utils/dateFns/date-fns";

export const dbCreateTrip = async (user, tripOpts) => {
    try {
        const tripRef = doc(collection(firestore, 'trips'))
        const today = new Date()
        const todayUTC = _fromLocalDate(today)
        let expiry = new Date()
        expiry.setDate(expiry.getDate() + 1)
        const expiryUTC = _fromLocalDate(expiry)

        let options = {
            id: tripRef.id,
            created: todayUTC,
            expiry: null,
            users: {},
            tripOpts,
            currentUsers: [],
        }
        // if not logged in, created trips have a preview property and has empty users. Should implement TTL
        // for preview trips that get deleted after certain amount of time
        if (user) {
            options.users[user.email] = {
                access: 'Owner',
                displayName: user.displayName
            }
        } else {
            options.preview = true
            options.expiry = expiryUTC
        }

        await setDoc(tripRef, options)
        return tripRef
    } catch (err) {
        console.log(err);
    }
}

export const dbGetTrip = async (tripId) => {
    try {
        let ref = doc(firestore, "trips", tripId);
        let tripData = (await getDoc(ref)).data()
        return tripData
    } catch (err){
        console.log(err.message)
    }
}

export const dbGetTrips = async (tripIds) => {
    if (!tripIds.length) return []
    try {
        const tripsRef = collection(firestore, 'trips')
        const q = query(tripsRef, where('id', 'in', tripIds))

        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map((doc) => {
            let data = doc.data()
            return {
                id: data.id,
                tripOpts: data.tripOpts,
                users: data.users,
            }
        });
    } catch (err){
        console.log(err)
    }
}

export const getTripRef = (tripId) => {
    return doc(firestore, "trips", tripId);
}

export const dbUpdateTripOpt = async (tripId, options) => {
    try {
        let ref = doc(firestore, 'trips', tripId)
        let opts = {}

        let keys = Object.keys(options)
        keys.forEach((o) => {
            opts[`tripOpts.${o}`] = options[o]
        })
        return await updateDoc(ref, opts);
    } catch (err){
        console.log(err)
    }
}

export const dbTripAddUsers = async (tripId, emails, access='Editor') => {
    try {
        const tripRef = getTripRef(tripId)
        const tripData = await dbGetTrip(tripId)
        let newUsers = {}
        emails.forEach((e) => {
            newUsers[e] = {
                access,
                displayName: ''
            }
        })
        let users = Object.assign(tripData.users, newUsers);
        await updateDoc(tripRef, {
            users
        })
    } catch (err){
        console.log(err)
    }
}

export const dbRemoveUserFromTrip = async (tripId, email) => {
    try {
        let ref = doc(firestore, 'trips', tripId)
        let data = await dbGetTrip(tripId)
        delete data.users[email]
        await updateDoc(ref, {
            users: data.users
        })
        return data.users
    } catch (err){
        console.log(err)
    }
}

export const dbTripDelete = async (tripId) => {
    try {
        return deleteDoc(doc(firestore, "trips", tripId));
    } catch (err){
        console.log(err)
    }
}

export const dbTripRemoveField = async (tripId, field) => {
    try {
        const ref = doc(firestore, 'trips', tripId)
        return await updateDoc(ref, {
            [`${field}`]: deleteField()
        });    
    } catch (err){
        console.log(err)
    }
}

export const dbTripRemoveExpiry = async (tripId) => {
    try {
        const ref = doc(firestore, 'trips', tripId)
        return await updateDoc(ref, {
            [`expiry`]: null
        });    
    } catch (err){
        console.log(err)
    }
}

export const dbTripUpdateField = async (tripId, opts) => {
    try {
        const ref = doc(firestore, 'trips', tripId)
        return await updateDoc(ref, opts);
    } catch (err){
        console.log(err)
    }
}