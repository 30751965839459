import { reauthenticate } from "../../../firebase/auth";
import styles from './account-profile.module.scss'
import { useState } from "react";
import { useForm } from "react-hook-form";
import { updatePassword, verifyBeforeUpdateEmail } from "firebase/auth";
import globaStore from "../../../global/globastore";
import { useGlobaContext } from "../../../global/globa-provider";
import { dbChangeDisplayName } from "../../../firebase/db/users";
import { IconExclamationCircle } from "@tabler/icons-react";
import { httpsCallable } from 'firebase/functions';
import firebaseFunctions from "../../../firebase/firebase-functions";

const AccountProfile = () => {
    const {
        formState: { errors },
        register,
        getValues,
        setError,
        clearErrors,
        resetField,
    } = useForm();

    const {toastNotification} = useGlobaContext()
    // const [editEmail, setEditEmail] = useState(false)
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const {currentUser} = globaStore()
    const [displayName, setDisplayName] = useState(currentUser.displayName)

    const [adminUser, setAdminUser] = useState('')

    const onSuccessNotification = (message, description) => {
        toastNotification('success', {
            message,
            description,
            // placement: 'top',
        });
    };

    const isValidName = (name) => {
        let regex = /[a-zA-z]/;
        if (regex.test(name) && !/^[0-9\W_]+$/.test(name)){
            return true
        } else {
            return false
        }
    }

    const onDisplayNameUpdate = () => {
        if (displayName.length < 1) {
            setError("displayName", {
                message: "Name too short"
            })
            return
        }
        if (!isValidName(displayName)) {
            setError("displayName", {
                message: "Invalid Name"
            })
            return
        }
        dbChangeDisplayName(displayName, currentUser.email)
            .then(() => {
                (setDisplayName(displayName))
                clearErrors('displayName')
                onSuccessNotification("Display Name Updated!")
            })
            .catch(err => {
                setError("displayNameErr", {
                    message: "Unable to change display name"
                })
                console.log(err)
            })
    }

    const onPasswordUpdate = () => {
        const {password, confirmPassword} = getValues()

        if (password.length < 6){
            setError("password", {
                message: 'Password cannot be less than 6 characters'
            })
            return
        } else if (confirmPassword !== password) {
            setError("confirmPassword", {
                message: 'Passwords do not match'
            })
            return
        } else {
            clearErrors(['password', 'confirmPassword'])
        }

        reauthenticate(currentUser, currentPassword).then(() => {
            clearErrors('currentPassword')
            if (currentPassword === newPassword) {
                setError("password", {
                    message: "Please use different password"
                })
                return
            }
            updatePassword(currentUser, password).then(() => {
                resetField('currentPassword')
                resetField('password')
                resetField('confirmPassword')
                onSuccessNotification('Password Updated')
            })
            .catch((error) => { console.log(error); });
        })
        .catch((err) => {
            if (err.message.indexOf('auth/invalid-email')){
                setError("currentPassword", {
                    message: 'Incorrect password',
                })
            }
        })    
    }

    // const onEmailUpdate = () => {
    //     const {email} = getValues()
    //     if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)){
    //         setError("email", {
    //             message: 'Please enter a valid email',
    //         })
    //         return
    //     } else {
    //         clearErrors('email')
    //     }

    //     reauthenticate(currentUser, currentPassword).then(() => {
    //         clearErrors('currentPassword')
    //         verifyBeforeUpdateEmail(currentUser, email).then(() => {
    //             resetField('currentPassword')
    //             reauthenticate(currentUser, currentPassword).then(() => {
    //                 setEditEmail(false)

    //                 onSuccessNotification('Email Sent', 'Please check for verification link in your new email')
    //             })
    //         })
    //         .catch((error) => { console.log(error); });
    //     })
    //     .catch((err) => {
    //         if (err.message.indexOf('auth/invalid-email')){
    //             setError("currentPassword", {
    //                 message: 'Incorrect password',
    //             })
    //         }
    //     })

    // }

    const disabledUpdateButtom = () => {
        return currentPassword.length && confirmPassword.length >= 6 && newPassword.length >= 6
    }

    const onAddAdminUser = () => {
        const setAdminClaim = httpsCallable(firebaseFunctions, 'setAdminClaim')
        const opts = {
            email: adminUser,
            claim: {
                admin: true,
                accessLevel: 10,
            }
        }
        setAdminClaim(opts).then((data) => {
            console.log(data)
        })
    }
    return (
        <>
        <div className={styles.accountProfileContainer}>
            <div className={styles.accountProfileSection}>
                <div className={styles.accountProfileTitle}>Details</div>
                <div className={styles.accountProfileRow}>
                    <div className={styles.accountProfileLabel}>E-mail : </div>
                    <span className={styles.accountProfileSpan}>{currentUser.email}</span>
                </div>
                <div className={styles.accountProfileRow}>
                    <div className={styles.accountProfileLabel}>Display Name:</div>
                    <div className="gap-4 flex">
                        <div>
                            <input 
                                type="text" 
                                className={styles.accountProfileInput} 
                                value={displayName}
                                onInput={(e) => setDisplayName(e.target.value)}
                            />
                        <div className={styles.formInputError}>
                            {errors.displayName && <span className="flex items-center gap-1"><IconExclamationCircle size={18} /> {errors.displayName.message}</span>}
                        </div>
                        </div>
                        <button disabled={displayName.trim() === currentUser.displayName || displayName.length < 1} className={`primary-button ${styles.customButton}`} onClick={() => onDisplayNameUpdate()}>Update</button>
                    </div>
                </div>
                <div className={styles.accountProfileRow}>
                    <div className={styles.accountProfileLabel}>Current Password  : </div>
                    <div>
                        <input 
                            type='password'
                            placeholder='Required'
                            className={styles.accountProfileInput} 
                            onInput={(e) => setCurrentPassword(e.target.value)}
                            {...register("currentPassword")}
                        ></input>
                        <div className={styles.formInputError}>
                            {errors.currentPassword && <span className="flex items-center gap-1"><IconExclamationCircle size={18} /> {errors.currentPassword.message}</span>}
                        </div>
                    </div>

                </div>
                <div className={styles.accountProfileRow}>
                    <div className={styles.accountProfileLabel}>New Password  : </div>
                    <div>
                        <input 
                            className={styles.accountProfileInput} 
                            type='password'
                            {...register("password")}
                            onInput={(e) => setNewPassword(e.target.value)}
                        ></input>
                        <div className={styles.formInputError}>
                            {errors.password && <span className="flex items-center gap-1"><IconExclamationCircle size={18} /> {errors.password.message}</span>}
                        </div>
                    </div>
                </div>
                <div className={styles.accountProfileRow}>
                    <div className={styles.accountProfileLabel}>Confirm New Password  : </div>
                    <div>
                        <div className="gap-4 flex">
                            <input 
                                className={styles.accountProfileInput} 
                                type='password'
                                {...register("confirmPassword")}
                                onInput={(e) => setConfirmPassword(e.target.value)}
                            ></input>
                            <button disabled={!disabledUpdateButtom()} className="primary-button" onClick={() => onPasswordUpdate()}>Update</button>
                        </div>
                        <div className={styles.formInputError}>
                            {errors.confirmPassword && <span className="flex items-center gap-1"><IconExclamationCircle size={18} /> {errors.confirmPassword.message}</span>}
                        </div>
                    </div>
                </div>
                {currentUser.email === 'taivihuynh@gmail.com' && <div className={styles.accountProfileRow}>
                    <div className={styles.accountProfileLabel}>Add Admin</div>
                    <div>
                        <div className="gap-4 flex">
                            <input 
                                className={styles.accountProfileInput} 
                                onInput={(e) => setAdminUser(e.target.value)}
                            ></input>
                            <button className="primary-button" onClick={() => onAddAdminUser()}>Add User</button>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
        </>
    );
};

export default AccountProfile;