import { Button, Dropdown } from 'antd';
import CityTags from '../../shared/components/city-tags/city-tags';
import styles from './guide.module.scss'
import Editor from '../../shared/components/editor/editor';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { dbGetGuide, dbGuideUpdate } from '../../../firebase/db/guides';
import LoadingSpinner from '../../shared/components/loading-spinner/loading-spinner';
import globaStore from '../../../global/globastore';

const Guide = () => {
    const {currentUser} = globaStore()
    const {id} = useParams()
    const location = useLocation()
    const type = location.pathname.split('/')[2] === 'official' ? 'official' : 'user'
    const [dataReady, setDataReady] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [editing, setEditing] = useState(false)
    const [data, setData] = useState({
        title: '',
        days: '',
        cities: [],
        content: {},
    })

    let items = new Array(14).fill({}).map((e,idx) => {
        return {
            key: idx + 1, 
            label: idx + 1,
            onClick: (item) => {
                setData({...data, days: item.key}) 
            }
        }
    })

    useEffect(() => {
        dbGetGuide(id, type).then((guide) => {
            setData(guide)
            document.title = `Palwego - ${guide.title}`
        })
        .catch((err) => console.log(err))
        .finally(() => setDataReady(true))
    }, [])

    useEffect(() => {
        if (currentUser){
            currentUser.getIdTokenResult().then((idTokenResult) => {
                setIsAdmin(idTokenResult.claims.admin)
            });
        }
    }, [currentUser])

    const handleTitleChange = (e) => setData({...data, title: e.target.value});

    const handleEditorChange = (content) => {
        setData({...data, content});
    };

    const handleCitiesChange = (cities) => {
        setData({...data, cities});
    }

    const onSave = () => {
        dbGuideUpdate(id, type, data).then((guide) => {
            return ('guide updated')
        })
        .catch((err) => console.log(err))
        .finally(() => setEditing(false))
    }

    return (
        <>
        {dataReady ?
            <div className={styles.bodyContainer}>
                <div className={styles.section}>
                    {!editing && <h2>{data.title}</h2>}
                    {editing && <input name="guide-name" type='text' className={styles.input + ' sc-input'} placeholder='Name' value={data.title} onChange={handleTitleChange}></input>}
                </div>
                <div className={styles.section}>  
                    {!editing && 
                    <>
                        <Button disabled={true} className={styles.button + " font-semibold"}>{data.days} Days</Button>
                        <CityTags cities={data.cities} readonly={true}/>         
                    </>  
                    }            
                    {editing && 
                    <>
                        <Dropdown
                            menu={{
                                items,
                                selectable: true,
                                defaultSelectedKeys: data.days ? [data.days] : [],
                            }} 
                            placement="bottomLeft"
                        >
                            <Button className={styles.button}>{data.days ? `${data.days} days` : 'Days'}</Button>
                        </Dropdown> 
                        <CityTags cities={data.cities} setCities={handleCitiesChange} />
                    </>
                    }           
                </div>
                <div className={styles.editorSection}>
                    {!editing && <Editor data={data.content} plugins={['image', 'unsplash']} readonly={true}/>}
                    {editing && <Editor data={data.content} onSaveCallback={handleEditorChange} plugins={['image', 'unsplash']} />}
                </div>
                {isAdmin && 
                    <div className={styles.footer}>
                        {!editing && <Button type='primary' onClick={() => setEditing(true)}>Edit Guide</Button>}
                        {editing && <Button type='primary' onClick={onSave}>Save</Button>}
                    </div>
                }
            </div>
            :
            <div className="h-screen flex justify-center items-center">
                <div className="h-60">
                    <LoadingSpinner></LoadingSpinner>
                </div>
            </div>
        }
        </>
    );
};

export default Guide;