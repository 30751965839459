import { useState } from "react";
import {useForm } from "react-hook-form";
import styles from './reset-password.module.scss'
import { Button } from "antd";
import { dbUserExists } from "../../../firebase/db/users";
import { sendResetPassword } from "../../../firebase/auth";
import { useGlobaContext } from "../../../global/globa-provider";
import authModalStore from "../modal/auth-modal.store";

const ResetPassword = () => {
    const {
        register,
        getValues,
        formState: { errors },
    } = useForm();

    const {toastNotification} = useGlobaContext()
    const {setCurrentTab} = authModalStore()
    const [errorsState, setErrors] = useState({error: false, message: ''})
    const [loading, setLoading] = useState(false)

    const onSubmit = async () => {
        try {
            setLoading(true)
            const {email} = getValues()
            let exists = await dbUserExists(email)
            if (!exists){
                setErrors({error: true, message: 'Account with the provided email does not exists'})
                setLoading(false)
                return
            } else {
                setErrors({error: false, message: ''})
            }
            
            sendResetPassword(email).then(() => {
                toastNotification('success', {
                    message: 'Reset Email Link Sent',
                    placement: 'bottom',
                    duration: 3,
                })
            })
            .catch(() => {
                toastNotification('error', {
                    message: 'Failed to send email',
                    placement: 'bottom',
                    duration: 3,
                })            
            })
            .finally(() => setLoading(false))

        } catch (e) {
            console.log(e.message)
        }
    }

    return (
        <>
        <form className={styles.form}>
            <div className={styles.formBody}>
                <input
                    className={styles.formInput}
                    type="text"
                    name="email"
                    placeholder="E-mail"
                    {...register("email")}
                />
                <div className={styles.formInputError}>
                    <span>{errors.email && errors.email?.message}</span>
                </div>
            </div>
            <div className={styles.formFooter}>
                {errorsState.error && <span className={styles.formAuthError}>{errorsState.message}</span>}
                <div className={styles.formAction}>
                    <Button type="primary" className={`${styles.primaryButton} ${styles.button}`} onClick={onSubmit} loading={loading}>Send Reset Password Link</Button>
                </div>
            </div>
        </form>
        &nbsp;
        <div className={styles.formAction}>
            <button className={`${styles.secondaryButton} ${styles.button}`} onClick={() => setCurrentTab('signin')}>Back to Sign In</button>
        </div>
        </>
    )
}

export default ResetPassword;