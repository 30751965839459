import { create } from "zustand";

const dashboardStore = create((set) => {
    return {
        tripData: null,
        setTripData: (tripData) => set({tripData}),
        scCalendar: null,
        setScCalendar: (scCalendar) => set({scCalendar}),
        tripId: null,
        setTripId: (tripId) => set({tripId}),
        chosenDate: Date(Date.now).toString(),
        setChosenDate: (chosenDate) => set({chosenDate}),
        inviteModal: false,
        setInviteModal: (inviteModal) => set({inviteModal}),
        tripUsers: [],
        setTripUsers: (tripUsers) => set({tripUsers}),
        calendarView: null,
        setCalendarView: (calendarView) => set({calendarView}),
        isVisitor: false,
        setIsVisitor: (isVisitor) => set({isVisitor})
    }
})

export default dashboardStore