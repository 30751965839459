import { create } from "zustand";

const tripsStore = create((set) => {
    return {
        upcomingTrips: [],
        setUpcomingTrips: (upcomingTrips) => set({upcomingTrips}),
        pastTrips: [],
        setPastTrips: (pastTrips) => set({pastTrips}),
        selectedTrip: null,
        setSelectedTrip: (selectedTrip) => set({selectedTrip}),
        removeSelfModal: false,
        setRemoveSelfModal: (removeSelfModal) => set({removeSelfModal})
    };
});

export default tripsStore