import { useState } from "react";
import {useForm } from "react-hook-form";
import { logout, signin } from '../../../firebase/auth'
import styles from './signin.module.scss'
import authModalStore from "../modal/auth-modal.store";
import GoogleButton from "../google-signin/google-signin";

const Signin = () => {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();
    const {setCurrentTab, setAuthModal} = authModalStore()
    const [errorsState, setErrors] = useState({error: false, message: ''})

    const onSubmit = async ({email, password}) => {
        try {
            await signin(email, password)
            setAuthModal(false)
        } catch (e) {
            if (e.message.indexOf('auth/invalid-email')) {
                setErrors({error: true, message: 'Incorrect e-mail/password'})
            } else {
                setErrors({error: true, message: e.message})
            }
        }
    }

    return (
        <>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formBody}>
                <input
                    className={styles.formInput}
                    type="text"
                    name="email"
                    placeholder="E-mail"
                    {...register("email", {
                        required: { value: true, message: "This field is required." },
                    })}
                />
                <div className={styles.formInputError}>
                    <span>{errors.email && errors.email?.message}</span>
                </div>
                <input
                    className={styles.formInput}
                    type="password"
                    name="password"
                    placeholder="Password"
                    {...register("password", {
                        required: { value: true, message: "Password is required." },
                    })}
                />
                <div className={styles.formInputError}>
                    <span>{errors.password && errors.password?.message}</span>
                </div>
            </div>
            <div className={styles.formFooter}>
                {errorsState.error && <span className={styles.formAuthError}>{errorsState.message}</span>}
                <div className={styles.formAction}>
                    <button className={`${styles.primaryButton} ${styles.button}`} type="submit">Sign In</button>
                </div>
                &nbsp;
            </div>
        </form>
        <div className={styles.formAction}>
            <button className={`${styles.secondaryButton} ${styles.button}`} onClick={() => setCurrentTab('reset')}>Reset Password</button>
        </div>
        &nbsp;
        <div className={styles.formAction}>
            <GoogleButton />
        </div>
        </>
    )
}

export default Signin;