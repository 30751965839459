import * as dateFns from 'date-fns'

export const formatDateMMMDDYY = (date) => {
    if (!date) return ''
    return dateFns.format(
        new Date(date),
        'MMM-dd-yy'
    )
}


export const formatDateMMMDDYYYY = (date) => {
    if (!date) return ''
    return dateFns.format(
        new Date(date),
        'MMM-dd-yyyy'
    )
}

export const isDateInRange = (date, startDate, endDate) => {
    // Convert all dates to milliseconds since epoch for easy comparison
    const dateMs = date ? new Date(date).getTime() : new Date().getTime();
    const startDateMs = new Date(startDate).getTime();
    const endDateMs = new Date(endDate).getTime();

    // Check if the date is within the range
    return dateMs >= startDateMs && dateMs <= endDateMs;
}

export const convertDateToString = (d) => {
    if (!(d instanceof Date)) return
    let dateElements = d.toString().split(' ')

    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    let dateString = [year, month, day].join('-');
    let timeString = dateElements[4]

    return dateString + ' ' + timeString
}

export const _fromLocalDate = (date) => {
    return new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
    ));
}