import { create } from "zustand";

const globaStore = create((set) => {
    return {
        currentUser: null,
        setCurrentUser: (currentUser) => set({currentUser}),
        trips: null,
        setTrips: (trips) => set({trips}),
        pathname: null,
        setPathname: (pathname) => set({pathname}),
        isAdmin: false,
        setIsAdmin: (isAdmin) => set({isAdmin})
    };
});

export default globaStore