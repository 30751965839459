import { create } from "zustand";

const authModalStore = create((set) => {
    return {
        authModal: false,
        setAuthModal: (authModal) => set({authModal}),
        currentTab: 'signin',
        setCurrentTab: (currentTab) => set({currentTab}),
        inviteModal: false,
        setInviteModal: (inviteModal) => set({inviteModal})
    }
})

export default authModalStore