import {
    setDoc,
    doc,
    getDoc,
    updateDoc,
} from 'firebase/firestore'
import firestore from './firestore';
import { _fromLocalDate } from '../../utils/dateFns/date-fns';

export const dbCreateFsq = async (fsq) => {
    try {
        let expiry = new Date()
        expiry.setDate(expiry.getDate() + 1)
        const expiryUTC = _fromLocalDate(expiry)
        setDoc(doc(firestore, 'fsq', fsq.fsq_id), {
            created: new Date(),
            expiry: expiryUTC,
            ...fsq
        })
    } catch (err) {
        console.log(err.message);
    }
}

export const dbGetFsq = async (id) => {
    try {         
        let fsqRef = doc(firestore, "fsq", id);
        let dbFsq = await getDoc(fsqRef)
        if (dbFsq){
            return dbFsq.data()
        } else {
            return null
        }
    } catch (err){
        console.log(err.message)
    }
}

export const dbUpdateFsq = async (fsq) => {
    try {
        const fsqRef = doc(firestore, "fsq", fsq.fsq_id);
    
        let today = new Date()
        await updateDoc(fsqRef, {
            created: today,
            ...fsq
        })
        return
    } catch (err) {
        console.log(err)
    }
}