import { getDoc } from 'firebase/firestore';
import { dbAddUser, dbGetUserRef, dbUserExists } from './db/users';
import firebase from './firebase'
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    sendEmailVerification,
    updateProfile,
    reauthenticateWithCredential,
    EmailAuthProvider,
    GoogleAuthProvider,
    signInWithPopup
} from 'firebase/auth'

export const firebaseAuth = getAuth(firebase);

export const signin = async (email, password) => {
    try {
        const res = await signInWithEmailAndPassword(firebaseAuth, email, password);
        const ref = await dbGetUserRef(res.user)
        const userSnap = await getDoc(ref)
        if (res.user.emailVerified && !userSnap.exists()){
            await dbAddUser(res.user, 'local')
        } 
    } catch (err) {
        throw err
    }
}

export const signup = async ({email, password, firstName, lastName}) => {
    try {
        const res = await createUserWithEmailAndPassword(firebaseAuth, email, password);
        let first = firstName[0].toUpperCase() + firstName.slice(1);
        let last = lastName[0].toUpperCase() + lastName.slice(1);
        await updateProfile(res.user, {
            displayName: `${first} ${last}`
        })
        await authVerifyEmail(res.user)
    } catch (err) {
        throw err
    }
};

export const resetPassword = async (email) => {
    try {
        await sendPasswordResetEmail(firebaseAuth, email);
    } catch (err) {
        throw err
    }
}

export const logout = () => {
    signOut(firebaseAuth)
}

export const authVerifyEmail = async (user) => {
    try {
        await sendEmailVerification(user)
    } catch (err) {
        console.log(err)
    }
}

export const reauthenticate = async (user, password) => {
    try {
        const cred = EmailAuthProvider.credential(user.email, password);
        return reauthenticateWithCredential(user, cred);
    } catch (err) {
        throw err
    }
}

export const sendResetPassword = async (email) => {
    try {
        sendPasswordResetEmail(firebaseAuth, email)
    } catch (err) {
        console.log(err.message)
    }
}

export const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(firebaseAuth, provider)
    .then(async result => {
        let exists = await dbUserExists(result.user.email)
        if (!exists) {
            dbAddUser(result.user, "google")
        }
    }).catch(err => {
        console.log(err)
    })
}

export const updateDisplayName = (name) => {
    const auth = getAuth()
    updateProfile(auth.currentUser, {
        displayName: name
    }).then(() => {
        console.log("updated display name")
    }).catch(err => {
        console.log(err)
    })
}