import { Button, Modal } from 'antd';
import styles from './copy-trip-modal.module.scss'
import { useState } from 'react';
import { dbCreateTrip } from '../../../../firebase/db/trips';
import globaStore from '../../../../global/globastore';
import { logEvent } from 'firebase/analytics';
import { firebaseAnalytics } from '../../../../firebase/analytics';
import { useNavigate } from 'react-router-dom';
import CityConfig from '../../../home/home-main/city-config/city-config';
import { IconCircleMinus, IconCirclePlus } from '@tabler/icons-react';
import dashboardStore from '../../dashboard-store';
import firebaseFunctions from '../../../../firebase/firebase-functions';
import { httpsCallable } from 'firebase/functions';
import { useNavigateToTrip } from '../../../../utils/react-utils/navigate';


const CopyTripModal = ({copyTripModal, setCopyTripModal, setIsLoading}) => {
    const navigateToTrip = useNavigateToTrip();

    const {currentUser} = globaStore()
    const {tripData} = dashboardStore()
    const [tripName, setTripName] = useState('')
    const [loadingCreate, setLoadingCreate] = useState(false)
    const [cities, setCities] = useState([
        {
            start: '',
            end: '',
            formattedAddress: '',
            name: '',
            country: '',
            geometry: {},
            validAddress: false,
        }
    ])

    let startDate, endDate

    const onTripNameChange = (e) => {
        setTripName(e.target.value)
    }

    const validatedInputs = () => {
        return tripName && cities.every((city) => {
            return city.validAddress && city.geometry && city.start && city.end
        })
    }

    const onTripCreate = async () => {
        setLoadingCreate(true)
        setIsLoading(true)
        let earliestDate
        let latestDate

        cities.forEach((city) => {
            let sDate = new Date(city.start)
            let eDate = new Date(city.end)

            if (!earliestDate || sDate < earliestDate){
                earliestDate = sDate
                startDate = city.start
            }
            if (!latestDate || eDate > latestDate){
                latestDate = eDate
                endDate = city.end
            }
        })

        const events = tripData.tripOpts.events.map((e) => {
            //convert firestore timestamp into date objects before passing to cloud functions
            if (e.start && e.start.toDate){
                e.start = e.start.toDate()
            }
            if (e.end && e.end.toDate){
                e.end = e.end.toDate()
            }
            e.price = {value: 0, currency: 'USD', eventUsers: []}
            return e
        })

        const itinOptions = {
            events,
            oldDates: {
                start: tripData.tripOpts.calendarStart,
                end: tripData.tripOpts.calendarEnd,
            },
            newDates: {
                start: startDate,
                end: endDate
            },
        }
        
        const copyitin = httpsCallable(firebaseFunctions, 'copyitin')
        copyitin(itinOptions).then(({data}) => { 
            data = data.map((e) => {
                //Firebase functions do not return date objects correctly, reconstruct to date objects here in response
                e.start = new Date(e.startString)
                e.end = new Date(e.endString)
                return e
            })   

            let tripOpts = {
                name: tripName,
                events: data,
                calendarStart: startDate,
                calendarEnd: endDate,
                cities
            }
            dbCreateTrip(currentUser, tripOpts).then((trip) => {
                logEvent(firebaseAnalytics, `analytics_tripName_${tripName}`)
                setLoadingCreate(false)
                setIsLoading(false)
                navigateToTrip(trip.id)
            }).catch((err) => {
                console.log(err)
            })
        }).catch((error) => {
            console.log(error)
        })
    }

    const addCity = () => {
        setCities([...cities, {
            start: '',
            end: '',
            formattedAddress: '',
            name: '',
            country: '',
            geometry: {},
            validAddress: false
        }])
    }

    const removeCity = () => {
        cities.splice(cities.length - 1, 1)
        setCities([...cities])
    }

    return (
        <Modal
            classNames={({
                wrap: styles.modalWrap,
                content: styles.modalContent,
            })}
            open={copyTripModal} 
            closeIcon={true}
            onCancel={() => setCopyTripModal(false)}
            title="Create New Trip"
            width={600}
            footer={false}
        >
            <div className={styles.createTripContainer}>
                <input id="trip-name" name="trip-name" type='text' className='sc-input' placeholder='Trip name' onChange={onTripNameChange}></input>
                {cities.map((city,idx) => (
                    <CityConfig key={idx} idx={idx} cities={cities} setCities={setCities}/> 
                ))}

                <div className='flex justify-between'>
                    <button
                        className='flex gap-1 text-sm items-center text-primary'
                        onClick={addCity}
                    >
                        <IconCirclePlus />
                        Add City
                    </button>
                    {cities.length > 1 && 
                        <button
                            className='flex gap-1 text-sm items-center text-danger'
                            onClick={removeCity}
                        >
                            <IconCircleMinus />
                            Remove City
                        </button>
                    }
                </div>
                <div className={styles.homeInputsAction}>
                    <Button 
                        type="primary"
                        disabled={!validatedInputs()} 
                        className='h-full w-full rounded-3xl' 
                        onClick={() => onTripCreate()}
                        loading={loadingCreate}
                    >Create</Button>
                </div>
            </div>
        </Modal>
    )
}

export default CopyTripModal