import { useLocation, useNavigate } from "react-router-dom";
import { firebaseAuth } from "../../../firebase/auth";
import { applyActionCode, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { useLayoutEffect, useRef, useState } from "react";
import { useGlobaContext } from "../../../global/globa-provider";
import LoadingSpinner from "../../shared/components/loading-spinner/loading-spinner";
import styles from './auth-link.module.scss'
import { useForm } from "react-hook-form";
import { IconExclamationCircle } from "@tabler/icons-react";
import { Button } from "antd";

const AuthLinks = () => {
    const {
        handleSubmit,
        formState: { errors },
        register,
        watch
    } = useForm();
    const {search} = useLocation()
    const navigate = useNavigate()
    const {toastNotification} = useGlobaContext()
    const firstRender = useRef(false)
    const [userEmail, setUserEmail] = useState('')
    const [linkExpired, setLinkExpired] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const [errorsState, setErrors] = useState({error: false, message: ''})


    const getParameterByName = (name) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        let results = regex.exec(search);
        if (!results) return null;
        if (!results[1]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const mode = getParameterByName('mode');
    const actionCode = getParameterByName('oobCode');
    const continueUrl = getParameterByName('continueUrl');
    const lang = getParameterByName('lang') || 'en';

    const handleVerifyEmail = (auth, actionCode) => {
        applyActionCode(auth, actionCode).then((res) => {
            toastNotification('success', {
                message: 'Email is verfied!'
            })
        })
        .catch ((err) => {
            console.error(err)
            toastNotification('error', {
                message: 'Unabled to verify email'
            })
        })
        .finally(() => 
            navigate('/')
        )
    }

    const handleResetPassword = (auth, actionCode) => {
        verifyPasswordResetCode(auth, actionCode).then((email) => {
            setUserEmail(email)
        })
        .catch((err) => {
            if (err.message.includes('invalid-action-code')){
                setLinkExpired(true)
            }
        })
        .finally(() => setPageLoading(false))
    }

    const onSubmitPassword = ({password}) => {
        confirmPasswordReset(firebaseAuth, actionCode, password).then(() => {
            toastNotification('success', {
                message: 'Password has been reset'
            })
            navigate('/')
        })
        .catch ((err) => {
            setErrors({error: true, message: err.code})
            console.error(err)
        })
    }

    useLayoutEffect(() => {
        if (firstRender.current) return
        if (actionCode) {
            firstRender.current = true
            switch (mode) {
                case 'verifyEmail': 
                    handleVerifyEmail(firebaseAuth, actionCode);
                    break
                case 'resetPassword':
                    handleResetPassword(firebaseAuth, actionCode)
                    break
                default:
                    console.log('No matching fireauth mode')
            }
        } else {
            console.error('No action code provided')
        }
    }, [actionCode])

    if (pageLoading) {
        return (
            <div className="h-screen flex justify-center items-center">
                <div className="h-60">
                    <LoadingSpinner></LoadingSpinner>
                </div>
            </div>
        )
    } else {
        switch (mode) {
            case 'resetPassword':
                if (!linkExpired){
                    return (
                        <div className={styles.resetPasswordPage}>
                            <div className={styles.resetPasswordContainer}>
                                <div className="flex flex-col gap-1 justify-center text-center pb-4">
                                    <span className="text-lg">Reset Password</span>
                                    <span className="text-xl font-medium">{userEmail}</span>
                                </div>
                                <div className={styles.formBody}>
        
                                    <form className={styles.form} onSubmit={handleSubmit(onSubmitPassword)}>
                                        <input
                                            className={styles.formInput}
                                            type="password"
                                            placeholder="Password"
                                            autoComplete="off"
                                            {...register("password", {
                                                required: { value: true, message: "Password is required." },
                                                minLength: { value: 6, message: "Password cannot be less than 6 characters"},
                                                maxLength: { value: 30, message: "Password cannot be more than 30 characters"}
                                            })}
                                        />
                                        <div className={styles.formInputError}>
                                            {errors.password?.message && <span className="flex gap-1"><IconExclamationCircle /> {errors.password.message}</span>}
                                        </div>
        
                                        <input
                                            className={styles.formInput}
                                            type="password"
                                            placeholder="Confirm Password"
                                            autoComplete="off"
                                            {...register("confirmPassword", {
                                                required: {value: true, message: "Confirm Password is required."},
                                                validate: value => value === watch("password", "") || "Passwords do not match"
                                            })}
                                        />
                                        <div className={styles.formInputError}>
                                            {errors.confirmPassword?.message && <span className="flex gap-1"><IconExclamationCircle /> {errors.confirmPassword.message}</span>}
                                        </div>
                                        <div className={styles.formFooter}>
                                            {errorsState.error && <span className={styles.formAuthError}>{errorsState.message}</span>}
                                            <div className={styles.formAction}>
                                                <button className={`${styles.primaryButton} ${styles.button}`} type="submit">Reset Password</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className={styles.resetPasswordPage}>
                            <div className={styles.resetPasswordContainer}>
                                <div className="flex flex-col gap-1 justify-center text-center pb-4">
                                    <span className="text-lg">Password reset link has expired</span>
                                </div>
                            </div>
                        </div>
                    )
                }
        }
    }
}

export default AuthLinks;