import { useNavigate } from 'react-router-dom';

export const useNavigateToTrip = () => {
    const navigate = useNavigate();

    const navigateToTrip = (tripId) => {
        navigate(`/trip/${tripId}`);
    };

    return navigateToTrip;
};