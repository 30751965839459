import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import styles from "./navbar.module.scss";
import { logout } from "../../firebase/auth";
import ClickOutside from "../../utils/react-utils/click-outside";
import palwegoLogoFull from '../../assets/logo/PALWEGO_horizontal.png'
import palwegoLogo from '../../assets/logo/PALWEGO_logo.png'
import globaStore from "../../global/globastore";
import navbarStore from "./navbar.store";
import authModalStore from "../auth/modal/auth-modal.store";
import AuthModal from "../auth/modal/auth-modal";
import { Button } from "antd";
import { IconChevronLeft, IconChevronRight, IconLayoutSidebarLeftCollapse, IconLayoutSidebarLeftExpand, IconUserCircle } from "@tabler/icons-react";
import { isMobile } from "react-device-detect";

const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [dropdownShow, setDropdownShow] = useState(false)
    const {currentUser, pathname, setPathname} = globaStore()
    const {setSidebarOpen, sidebarOpen, setPage, page} = navbarStore()
    const {setAuthModal} = authModalStore()
    const [logo, setLogo] = useState(null)

    useEffect(() => {
        if (location) {
            setPathname(location.pathname)
            if (location.pathname === '/'){
                setPage('home')
            } else if (location.pathname.indexOf('/trip/') >= 0){
                setPage('dashboard')
            } else if (location.pathname === '/trips'){
                setPage('trips')
            } else if ( location.pathname === '/guides') {
                setPage('guides')
            } else {
                setPage('')
            }
        }
    }, [location, setPathname])

    useEffect(() => {
        if (isMobile){
            setLogo(palwegoLogo)
        } else {
            setLogo(palwegoLogoFull)
        }
    }, [isMobile])

    const logOutHandler = async () => {
        try {
            await logout()
            navigate('/');
        } catch (error) {
            console.log("Something went wrong.", error);
        }
    }

    const toggleDropdown = () => setDropdownShow(!dropdownShow)
    const hideDropdown = () => setDropdownShow(false)

    return (
        <>
        <header className={styles.header}>
            <div className={styles.navigation}>
                <div className={styles.navSide}>
                    <img src={logo} className={styles.logo} onClick={() => navigate('/')} alt=''></img>
                    {page === 'dashboard' && 
                        <button className={styles.sidebarButton}>
                            {!sidebarOpen && <IconChevronRight size={24} stroke={2} onClick={() => setSidebarOpen(!sidebarOpen)} className="cursor-pointer text-primary"/>}
                            {sidebarOpen && <IconChevronLeft size={24} stroke={2} onClick={() => setSidebarOpen(!sidebarOpen)} className="cursor-pointer text-primary"/>}
                        </button>
                    }
                </div>
                <div className={styles.navSide}>
                    {/* <button 
                        className={styles.navItem + " " + (page === 'guides' ? styles.navItemActive : "") + " " + "text-lg font-bold"}
                        onClick={() => {
                            navigate('/guides')
                        }}
                    >
                        Explore
                    </button>  */}
                    {!currentUser && 
                        <div className={styles.nonUserContainer}>
                            <Button type="primary" className={styles.signUpButton} onClick={() => setAuthModal(true)}>Sign in | Sign up</Button>
                        </div>
                    }
                    {currentUser && 
                        <React.Fragment>
                            <button 
                                className={styles.navItem + " " + (page === 'trips' ? styles.navItemActive : "") + " " + "text-lg font-bold"}
                                onClick={() => {
                                    navigate('/trips')
                                }}
                            >
                                Trips
                            </button>
                            <ClickOutside onClick={hideDropdown}>
                                <div className={styles.navUserContainer}>
                                    <div className={styles.navUser} onClick={toggleDropdown}>
                                        {currentUser.displayName && <span className="text-lg ">{currentUser.displayName.split(' ')[0]}</span>}
                                        <IconUserCircle size={30} className="color-primary"/>
                                    </div>
                                        <ul 
                                            className={`${styles.navUserDropdown} ${dropdownShow ? styles.dropdownShow : ''}`}
                                        >
                                            <li 
                                                className={styles.navigationItem}
                                                onClick={() => {
                                                    toggleDropdown(); 
                                                    navigate('/account')
                                                }}
                                            >
                                                Account
                                            </li>
                                            <li 
                                                className={styles.navigationItem}
                                                onClick={() => {
                                                    toggleDropdown(); 
                                                    logOutHandler();
                                                }}
                                            >
                                                Log out
                                            </li>
                                        </ul>
                                </div>
                            </ClickOutside>
                        </React.Fragment>
                    }
                </div>
            </div>
        </header>
        <AuthModal />
        </>
    );
}

export default Navbar;
