import { useEffect, useRef } from "react";
import EditorJS from '@editorjs/editorjs';
import Hyperlink from 'editorjs-hyperlink';
import List from "@editorjs/list";
import Checklist from "@editorjs/checklist";
import Undo from 'editorjs-undo';
import DragDrop from 'editorjs-drag-drop';
import SimpleImage from "@editorjs/simple-image";
import InlineImage from 'editorjs-inline-image';
import Header from 'editorjs-header-with-alignment'

const Editor = ({data, onSaveCallback, plugins=[], readonly=false}) => {

    const ejInstance = useRef();
    const firstRender = useRef(false)
    useEffect(() => {
        if (firstRender.current) return

        if (!firstRender.current) {
            firstRender.current = true
            initEditor();
        }

        return () => {
            ejInstance?.current?.destroy();
            ejInstance.current = null;
        };
    }, []);

    const tools = {
        header: Header,
        list: List,
        checklist: {
            class: Checklist,
            inlineToolbar: true,
        },               
        hyperlink: {
            class: Hyperlink,
            config: {
                shortcut: 'CMD+L',
                target: '_blank',
                rel: 'nofollow',
                availableTargets: ['_blank', '_self'],
                availableRels: ['author', 'noreferrer'],
                validate: false,
            }
        }
    }

    if (plugins.includes('image')){
        tools.image = SimpleImage
    }

    if (plugins.includes('unsplash')){
        tools.unsplash = {
            class: InlineImage,
            inlineToolbar: true,
            config: {
                embed: {
                    display: true,
                },
                unsplash: {
                    appName: 'palwego',
                    apiUrl: 'https://us-central1-palwego-dev.cloudfunctions.net/unsplashproxy',
                    maxResults: 10,
                }
            }
        }
    }

    const initEditor = () => {
        const editor = new EditorJS({
            holder: 'editorjs',
            onReady: () => {
                ejInstance.current = editor;
                new Undo({ editor });
                new DragDrop(editor);
            },
            readOnly: readonly,
            autofocus: false,
            data: data,
            logLevel: 'ERROR',
            onChange: readonly ? () => {} : async () => {
                let content = await editor.saver.save();
                onSaveCallback(content)
            },
            tools,
        });
    };
    return  <div id='editorjs'></div>;
}

export default Editor