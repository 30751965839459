import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../navbar/navbar";
import styles from './layout.module.scss'
import { useEffect } from "react";
import ReactGA from 'react-ga4';
import { VerifyEmail } from "../home/verify-email/verify-email";
import { isMobile } from 'react-device-detect';
import dashboardStore from "../dashboard/dashboard-store";
import navbarStore from "../navbar/navbar.store";

const Layout = () => {
    const location = useLocation();

    const {calendarView} = dashboardStore()
    const {sidebarOpen, page} = navbarStore()

    useEffect(() => {
        ReactGA.send(location.pathname + location.search);
    }, [location]);

    return (
        <div className={`${(isMobile && calendarView === 'listWeek') ? styles.layoutMobile : styles.layout}` + ' ' + `${sidebarOpen ? 'overflow-y-hidden' : ''}` + 'sc'}>
            <Navbar></Navbar>
            <Outlet />
            <VerifyEmail/>
        </div>
    );
};

export default Layout;