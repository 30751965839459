import { Button, Dropdown } from 'antd';
import Editor from '../../shared/components/editor/editor';
import styles from './guide-create.module.scss'
import { useState } from 'react';
import CityTags from '../../shared/components/city-tags/city-tags';
import { dbCreateGuideOfficial } from '../../../firebase/db/guides';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../shared/components/loading-spinner/loading-spinner';

const GuideCreate = () => {

    document.title = `Palwego - New Guide`
    const navigate = useNavigate()
    const [title, setTitle] = useState('');
    const [days, setDays] = useState(null);
    const [content, setContent] = useState(null)
    const [cities, setCities] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    let items = new Array(14).fill({}).map((e,idx) => {
        return {
            key: idx + 1, 
            label: idx + 1,
            onClick: (item) => {
                setDays(item.key) 
            }
        }
    })

    const handleTitleChange = (e) => setTitle(e.target.value);

    const handleEditorChange = (content) => {
        setContent(content);
    };

    const handleSubmit = () => {
        setIsLoading(true)
        const data = {
            title,
            days,
            cities: cities.map((c) =>  c.value),
            content,
        };

        dbCreateGuideOfficial(data).then((guide) => {
            navigateToGuide(guide.id)
        }).catch((err) => {
            console.log(err)
        }) 
        .finally(() => setIsLoading(false))  
    };

    const navigateToGuide= (id) => {
        navigate(`/guide/official/${id}`)
    }    

    return (
        <>
        {isLoading ? 
            <div className="h-screen flex justify-center items-center">
                <div className="h-60">
                    <LoadingSpinner></LoadingSpinner>
                </div>
            </div>
            :
            <div className={styles.bodyContainer}>
                <div className={styles.section}>
                    <input name="guide-name" type='text' className={styles.input + ' sc-input'} placeholder='Guide Name' onChange={handleTitleChange}></input>
                </div>
                <div className={styles.section}>                
                    <Dropdown
                        menu={{
                            items,
                            selectable: true,
                            defaultSelectedKeys: days ? [days] : [],
                        }} 
                        placement="bottomLeft"
                    >
                        <Button className={styles.button}>{days ? `${days} days` : 'Days'}</Button>
                    </Dropdown>  
                    <CityTags setCities={setCities} cities={cities}/>              
                </div>
                <div className={styles.editorSection}>
                    <Editor data={content} onSaveCallback={handleEditorChange} plugins={['image', 'unsplash']} />
                </div>
                <div className={styles.footer}>
                    <Button type='primary' onClick={handleSubmit}>Create</Button>
                </div>
            </div>
        }
        </>
    );
};


export default GuideCreate;
