import { IconArrowsMaximize, IconArrowsMinimize, IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import styles from './trip-notes.module.scss'
import { useCallback, useEffect, useState } from 'react'
import { Modal } from 'antd'
import { debounce } from 'lodash';
import dashboardStore from '../../dashboard-store';
import { dbTripUpdateField } from '../../../../firebase/db/trips';
import Editor from '../../../shared/components/editor/editor';

const TripNotes = () => {
    const {tripId, tripData} = dashboardStore()
    const [expand, setExpand] = useState(false)
    const [notes, setNotes] = useState(null)
    const [dataReady, setDataReady] = useState(false)
    const [notesOpen, setNotesOpen] = useState(false)

    useEffect(() => {
        if (tripData){
            setNotes(tripData.tripNotes)
            setDataReady(true)
        }
    }, [tripData])

    const handleDebounceFn = (content) => {
        dbTripUpdateField(tripId, {
            'tripNotes': content
        })
    }

    const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

    const handleChange = (content) => {
        setNotes(content);
        debounceFn(content);
    };

    if (expand) {
        return (
            <Modal
                classNames={({
                    content: styles.modalContent,
                })}
                open={expand} 
                footer={null}
                width={600}
                closeIcon={<IconArrowsMinimize/>}
                onCancel={() => setExpand(false)}
            >
                <div className={styles.notesInputMaximize}>
                    <Editor data={notes} onSaveCallback={handleChange} />
                </div>
            </Modal>
        )
    } else {
        return (
            <div className={styles.notesContainer}>
                <div className={`${styles.notesHeader} flex justify-between items-center` }>
                    <span className='font-semibold'>Notes</span>
                    <div className={`${styles.notesHeaderIcons} flex gap-2 items-center`}>
                        {!notesOpen && <IconChevronDown size={22} className={`${styles.notesIcon} cursor-pointer`} onClick={() => setNotesOpen(true)}/>}
                        {notesOpen && <IconChevronUp size={22} className={`${styles.notesIcon} cursor-pointer`} onClick={() => setNotesOpen(false)}/>}
                        <IconArrowsMaximize size={18} className={`${styles.notesIcon} cursor-pointer`} onClick={() => setExpand(true)}/>
                    </div>
                </div>
                {(dataReady && notesOpen) &&
                    <div className={styles.notesInputMinimize}>
                        <Editor data={notes} onSaveCallback={handleChange} />
                    </div>
                }
            </div>
        )
    }


}

export default TripNotes
