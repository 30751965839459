import {createBrowserRouter} from "react-router-dom";
import Home from "../../components/home/home";
import Layout from "../../components/layout/layout";
import Dashboard from "../../components/dashboard/dashboard";
import AccountPage from "../../components/account-page/account-page";
import Trips from "../../components/trips/trips";
import PrivateRoute from "./private-route";
import Redirect from "../../components/auth/redirect/redirect";
import AuthLinks from "../../components/auth/auth-links/auth-links";
import Guides from "../../components/guides/guides";
import GuideCreate from "../../components/guides/guide-create/guide-create";
import AdminRoute from "./admin-route";
import Guide from "../../components/guides/guide/guide";

const navigationRouter = createBrowserRouter([
    {
        element: <Layout />,
        children: [
        {
            path: "/",
            element: <Home />
        }, 
        {
            path: '/trips',
            element: (
                <PrivateRoute>
                    <Trips />
                </PrivateRoute>
            )
        },
        {
            path: '/trip/:id',
            element: <Dashboard />
        },
        {
            path: '/account',
            element: (
                <PrivateRoute>
                    <AccountPage />
                </PrivateRoute>
            )
        },
        {
            path: "/redirect",
            element: <Redirect />
        },
        {
            path: "/authlink/*",
            element: <AuthLinks />
        },
        {
            path: "/guides",
            element: <Guides />
        },
        {
            path: "/guide-create",
            element: (
                <AdminRoute>
                    <GuideCreate />
                </AdminRoute>
            )
        },
        {
            path: "/guide/official/:id",
            element: (
                <Guide />
            )
        },
        {
            path: "/guide/:id",
            element: (
                <Guide />
            )
        },
        {
            path: "*",
            element: <Home />
        },
    ],
    },
]);

export {navigationRouter}