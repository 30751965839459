import styles from "./members.module.scss";
import { Avatar, Tooltip } from "antd";
import globaStore from '../../../../../global/globastore';
import RemoveButton from "../remove-button/remove-button";
import { UserOutlined } from '@ant-design/icons';
import dashboardStore from "../../../dashboard-store";

const Members = ({tripId}) => {

    const {currentUser} = globaStore();
    const {tripData, tripUsers, setTripUsers} = dashboardStore()

    const handleRemoveUser = (removedEmail) => {
        setTripUsers(tripUsers.filter(user => user.email !== removedEmail));
    };

    return (
        <div className={styles.tripMember}>
            <p className={styles.tripMemberText}>Invited:</p>
            <div className={styles.currentMembers}>
                {tripUsers?.map(user => (
                    <div key={user.email} className={styles.displayName}>
                        <div className={styles.innerName}>
                            {user.displayName && <>
                                <Tooltip placement="top" title={user.email}>
                                    <Avatar key={user.email} className={styles.avatarActive}>{user?.displayName[0]}</Avatar>
                                    <span className={styles.nameLabel}>{user.displayName}</span>
                                </Tooltip>
                            </>}
                            {!user.displayName && <>
                                <Avatar key={user.email} icon={<UserOutlined/>} />
                                <span className={styles.nameLabel}>{user.email}</span>    
                            </>}
                        </div>
                        {(tripData.users[currentUser.email] === "Owner" && user.email !== currentUser.email) && <RemoveButton email={user.email} tripId={tripId} onRemoveUser={handleRemoveUser}/>}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Members