import { ConfigProvider, Menu } from 'antd';
import styles from './account-page.module.scss'
import AccountProfile from './account-profile/account-profile';
import { useState } from 'react';
import { IconUserCircle } from '@tabler/icons-react';

const AccountPage = () => {
    const [currentTab, setCurrentTab] = useState('profile');

    document.title = `Palwego - Account`

    const items = [
        {
            label: 'Profile',
            key: 'profile',
            icon: <IconUserCircle size={16} />,
            className: 'accountPageMenuItem',
        }
    ]
    const onClick = (e) => {
        setCurrentTab(e.key);
    };

    return (
        <>
            <div className={styles.accountPageBody}>
                <Menu className={styles.accountPageMenu} onClick={onClick} selectedKeys={[currentTab]} mode="horizontal" items={items} style={{ itemColor: "red"}} />

                <div className={styles.accountPageItemContainer}>
                    {currentTab === 'profile' && <AccountProfile />}
                </div>
            </div>
        </>
    );
};

export default AccountPage;