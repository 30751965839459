import { useEffect, useState } from "react"
import styles from './verify-email.module.scss'
import { Modal, Button } from 'antd'
import { authVerifyEmail, logout } from "../../../firebase/auth"
import globaStore from "../../../global/globastore"

export const VerifyEmail = () => {
    const {currentUser} = globaStore()
    const [resendLoading, setResendLoading] = useState(false)
    const [verifyModal, setVerifyModal] = useState(false)

    useEffect(() => {
        if (currentUser && !currentUser.emailVerified){
            setVerifyModal(true)
        }
    }, [currentUser])

    const onModalClose = () => {
        setVerifyModal(false)
        logout()
    }

    const resend = async () => {
        try {
            setResendLoading(true)
            await authVerifyEmail(currentUser)
            setResendLoading(false)
            onModalClose()
        } catch (err){
            console.log(err)
            setResendLoading(false)
        }
    }
    return (
        <Modal
            open={verifyModal} 
            onCancel={onModalClose}
            title="Please Verify Email"
            footer={[
                <Button
                    key="link"
                    type="primary"
                    loading={resendLoading}
                    onClick={resend}
                >Resend Verification Link</Button>
            ]}
        >
            <div className='w-full'>
                Please check your email for verification link then sign in
            </div>
        </Modal>
    )

}