import { useEffect, useRef, useState } from 'react'
import { Avatar, Button, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Tagify from '@yaireo/tagify'
import styles from './invite-modal.module.scss'
import globaStore from '../../../../global/globastore';
import dashboardStore from '../../dashboard-store';
import { dbTripAddUsers } from '../../../../firebase/db/trips';
import { useGlobaContext } from '../../../../global/globa-provider';
import Members from "./members/members"
import { httpsCallable } from "firebase/functions";
import firebaseFunctions from '../../../../firebase/firebase-functions';
import ReactGA from 'react-ga4';

const InviteModal = ({tripOpts, tripId}) => {

    const [inviteEmails, setInviteEmails] = useState([])
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [lastTripUsers, setLastTripUsers] = useState([]);
    const {currentUser, trips} = globaStore()
    const {inviteModal, setInviteModal, tripUsers} = dashboardStore()
    const {toastNotification} = useGlobaContext()

    const tagify = useRef()
    const tagRef = useRef()

    useEffect(() => {
        if (trips && trips.length){
            let today = new Date()

            let past = trips
                .filter((t) => new Date(t.tripOpts.calendarStart) < today)
                .sort((a,b) => new Date(a.tripOpts.calendarStart) - new Date(b.tripOpts.calendarStart))
            
            if (past && past.length){
                let lastTripWithUsers = past.find((trip) => {
                    let usersLen = []
                    if (trip.users){
                        usersLen = Object.keys(trip.users)
                    }
                    if (usersLen.length){
                        return (usersLen.length > 1)
                    } else {
                        return false
                    }
                })
                if (lastTripWithUsers) {
                    let usersCopy = {...lastTripWithUsers.users}
                    tripUsers.forEach((u) => {
                        if (usersCopy[u.email]){
                            delete usersCopy[u.email]
                        }
                    })
                    usersCopy = Object.keys(usersCopy)
                    setLastTripUsers(usersCopy)
                }
            }
        }
    }, [trips])

    useEffect(() => {
        if (inviteModal) {
            tagify.current = new Tagify(
                tagRef.current,
                {
                    pattern: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-z]{2,})$/,
                    delimiters: ',| ',
                    maxTags: 15,
                    originalInputValueFormat: (valuesArr) =>
                        valuesArr.map((item) => item.value).join(',')
                }
            )
            tagify.current.on('change', (event) => setInviteEmails(event.detail.value.split(',')))
        }
    }, [inviteModal])

    const invite = async () => {
        ReactGA.event({
            category: 'Button Click',
            action: 'sending invite email',
            label: 'Invite email'
        })
        const emailOptions = {
            trip: tripOpts.name,
            to: inviteEmails, 
            from: currentUser.displayName || currentUser.email,
            tripId: tripId,
            access: 'edit'
        }
        
        setConfirmLoading(true)
        const sendmail = httpsCallable(firebaseFunctions, 'sendmail')
        sendmail(emailOptions).then((res) => {
            dbTripAddUsers(tripId, inviteEmails).then(() => {
                setConfirmLoading(false)
                toastNotification('success', {message: 'Email sent!'})
            })
            .catch((err) => {
                console.log(err)
            })
        })
        .catch((err) => {
            ReactGA.exception({
                description: 'Invite email failed',
                fatal: true
            });            
            setConfirmLoading(false)
            toastNotification('error', {message: 'Email failed to send'})
        })
    }

    const onAddUserClick = (email) => {
        tagify.current.addTags(email)
        let emailIdx = lastTripUsers.indexOf(email)
        lastTripUsers.splice(emailIdx, 1)
        setLastTripUsers(lastTripUsers)
    }

    return (
        <Modal
            classNames={({
                wrap: styles.modalWrap,
                content: styles.modalContent,
            })}
            open={inviteModal} 
            closeIcon={false}
            onCancel={() => setInviteModal(false)}
            title="Invite"
            width={600}
            footer={[
                <Button
                    className={styles.submitButton}
                    key="link"
                    type="primary"
                    loading={confirmLoading}
                    onClick={invite}
                    disabled={!inviteEmails.length}
                >Send</Button>
            ]}
        >
            <div className='w-full'>
                <textarea ref={tagRef} className={styles.inviteEmailInput} placeholder='Enter Emails'></textarea>
            </div>
            {lastTripUsers.length ? 
                <div className={styles.tripMember}>
                    <p className={styles.tripMemberText}>Invite from your previous trip:</p>
                    <div className={styles.currentMembers}>
                        {lastTripUsers.map(email => (
                            <div key={email} className={styles.displayName}>
                                <div className={styles.innerName}>
                                    <Avatar key={email} className={styles.avatarActive} icon={<UserOutlined/>}/>
                                    <span className={styles.nameLabel}>{email}</span>
                                </div>
                                <button onClick={() => onAddUserClick(email)} className={`${styles.addButton} sc-btn sc-btn-primary`}>Add</button>
                            </div>
                        ))}
                    </div>
                </div>
            : <></>}
            <Members tripId={tripId}/>
        </Modal>
    )
}

export default InviteModal