import styles from "./google-signin.module.scss";
import { googleSignIn } from "../../../firebase/auth";
import { IconBrandGoogleFilled } from "@tabler/icons-react";

const GoogleButton = () => {

    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn()
        } catch (err) {
            console.log(err)
        }
    }
    return (
    <div className="asd">
        <button className={`${styles.googleButton} ${styles.button}`} onClick={handleGoogleSignIn}>
            <IconBrandGoogleFilled />
            &nbsp;
            Sign In With Google
        </button>
    </div>
    )
}

export default GoogleButton;
