import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import firestore from "./firestore";
import { FirebaseError } from "firebase/app";

interface GuideData {
    title:string;
    days:string;
    cities:any[];
    content:any,
    created?:Date,
}

export const dbCreateGuideOfficial = async (data:GuideData) => {
    try {
        const guideRef = doc(collection(firestore, 'guides_official'))
        const today = new Date()

        data.created = today

        await setDoc(guideRef, data)
        return guideRef
    } catch (err) {
        if (err instanceof FirebaseError) {
            console.error('Firestore Error:', err.code, err.message);
            throw new Error(`Firestore Error: ${err.code} - ${err.message}`);
        } else {
            console.error('Unknown Error:', err);
            throw new Error('An unknown error occurred');
        }    
    }
}

export const dbCreateGuideUser = async (data:GuideData) => {
    try {
        const guideRef = doc(collection(firestore, 'guides_user'))
        const today = new Date()

        data.created = today

        await setDoc(guideRef, data)
        return guideRef
    } catch (err) {
        if (err instanceof FirebaseError) {
            console.error('Firestore Error:', err.code, err.message);
            throw new Error(`Firestore Error: ${err.code} - ${err.message}`);
        } else {
            console.error('Unknown Error:', err);
            throw new Error('An unknown error occurred');
        }    
    }
}

export const dbGetGuide = async (id:string, type:'official'|'user') => {
    try {
        let ref = doc(firestore, `guides_${type}`, id);
        let data = (await getDoc(ref)).data()
        return data
    } catch (err){
        if (err instanceof FirebaseError) {
            console.error('Firestore Error:', err.code, err.message);
            throw new Error(`Firestore Error: ${err.code} - ${err.message}`);
        } else {
            console.error('Unknown Error:', err);
            throw new Error('An unknown error occurred');
        }      
    }
}

export const dbGuideUpdate = async (id:string, type:'official'|'user', opts:any) => {
    try {
        const ref = doc(firestore, `guides_${type}`, id)
        return await updateDoc(ref, opts);
    } catch (err){
        console.log(err)
    }
}