import { create } from "zustand";

const navbarStore = create((set) => {
    return {
        sidebarOpen: false,
        setSidebarOpen: (sidebarOpen) => set({sidebarOpen}),
        page: 'home',
        setPage: (page) => set({page}),
    }
})

export default navbarStore