import styles from './guides.module.scss'

const Guides = () => {

    document.title = `Palwego - Explore`

    return (
        <>
            <div>

            </div>
        </>
    );
};

export default Guides;