import styles from './home-showcase.module.scss'

const HomeShowcase = () => {


    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.section}>
                    <div className={styles.container}>
                        <img src='https://storage.googleapis.com/palwego-static/home/Calendar.png'></img>
                    </div>
                </div>
                <div className={styles.section}>
                    <div className={styles.container}>
                        <h1 className={styles.title}>Say goodbye to Word docs and spreadsheets</h1>
                        <span className={styles.description}>Planning trips has never been easier. Organize your itinerary with drag, drop and resize features. Discover activities and must-see destinations without the need to switch tabs. Personalize your events by assigning categories and adding your favorite colors</span>
                    </div>
                </div>
            </div>

            <div className={`${styles.wrapper} ${styles.reversed}`}>
                <div className={styles.section}>
                    <div className={styles.container}>
                        <img src='https://storage.googleapis.com/palwego-static/home/invite.jpg'></img>
                    </div>
                </div>
                <div className={styles.section}>
                    <div className={styles.container}>
                        <h1 className={styles.title}>Planning can be fun!</h1>
                        <span className={styles.description}>Collaborate with friends and family to plan your trip together, with live updates reflecting changes in real-time. Not in the mood to plan the entire trip yourself? Simply request their itineraries and customize them to make your own</span>
                    </div>
                </div>
            </div>

            <div className={styles.wrapper}>
                <div className={styles.section}>
                    <div className={styles.container}>
                        <img src='https://storage.googleapis.com/palwego-static/home/mobile.jpg'></img>
                    </div>
                </div>
                <div className={styles.section}>
                    <div className={styles.container}>
                        <h1 className={styles.title}>Travel hassle free</h1>
                        <span className={styles.description}>Travel with ease and confidence. Keep every detail of your trip at your fingertips with our summary view, ensuring you stay organized every step of the way</span>
                    </div>
                </div>
            </div>
        </>
    )

}

export default HomeShowcase