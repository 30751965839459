import React, { useEffect, useState } from 'react'
import ScCalendar from './sc-calendar/sc-calendar';
import Sidebar from './sidebar/sidebar';
import { dbTripAddUsers, dbGetTrip, dbTripRemoveField, dbUpdateTripOpt, getTripRef, dbTripRemoveExpiry } from '../../firebase/db/trips';
import { dbGetNameFromTrip, dbUserAddTrip } from '../../firebase/db/users';
import { dbCreateFsq, dbGetFsq, dbUpdateFsq } from '../../firebase/db/fsq';
import { onSnapshot } from 'firebase/firestore';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import globaStore from '../../global/globastore';
import dashboardStore from './dashboard-store';
import style from './dashboard.module.scss';
import RedirectModal from './redirect-modal/redirect-modal';
import PreviewModal from './preview-modal/preview-modal';
import { Button } from 'antd';
import navbarStore from '../navbar/navbar.store';
import LoadingSpinner from '../shared/components/loading-spinner/loading-spinner';
import { isMobile } from 'react-device-detect';
import { isDateInRange } from '../../utils/dateFns/date-fns';

const Dashboard = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { id } = useParams();

    const {currentUser, trips, setTrips} = globaStore()
    const {setChosenDate, tripId, setTripId, setTripData, setTripUsers, calendarView, setIsVisitor} = dashboardStore()
    const {setSidebarOpen, sidebarOpen} = navbarStore()
    const [noAccess, setNoAccess] = useState(false);
    const [noTrip, setNoTrip] = useState(false)
    const [previewModal, setPreviewModal] = useState(false);
    const [redirectModal, setRedirectModal] = useState(false);
    const [tripOpts, setTripOpts] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setTripId(id)
        if (!isMobile){
            setSidebarOpen(true)
        }
    }, [])

    useEffect(() => {
        if (id){
            dbGetTrip(id).then((trip) => {
                if (trip) {
                    if (!currentUser){
                        if (trip.preview){
                            // preview mode should allow user to test out the calendar features, but any change will not be saved to firestore.
                            // all changes are purely frontend
                            setPreviewModal(true)
                        } else if (trip.public){
                            setIsVisitor(true)
                        } else {
                            // if not logged in and trip is not preview, ask users to log in
                            setRedirectModal(true)
                            setIsLoading(false)
                            return
                        }
                    } else {
                        if (trip.preview) {
                            // if logged in and in preview, that user becomes the owner of the trip and trip becomes non-preview
                            dbTripRemoveField(trip.id, 'preview')
                            dbTripRemoveExpiry(trip.id)
                            dbTripAddUsers(trip.id, [currentUser.email], 'Owner')
                            dbUserAddTrip(trip.id, currentUser.email)
                        } else if (!trip.users[currentUser.email]){
                            if (trip.public){
                                setIsVisitor(true)
                            } else {
                                // if logged in and trip does not contain user, show no access
                                setNoAccess(true)
                                setIsLoading(false)
                                return
                            }
                        } else if (trip.users[currentUser.email]){
                            // if trip is not preview, and user is logged in, and trip contains user, always attempt to 
                            // add the current trip to user[trip], if trip already exists in user's trip object, it will do nothing
                            dbUserAddTrip(trip.id, currentUser.email)
                            setIsVisitor(false)
                        }
                    }
                    
                    trip.tripOpts.dbUpdateTripOpt = dbUpdateTripOpt
                    trip.tripOpts.dbCreateFsq = dbCreateFsq
                    trip.tripOpts.dbGetFsq = dbGetFsq
                    trip.tripOpts.dbUpdateFsq = dbUpdateFsq
                    trip.tripOpts.tripId = id
                    setTripOpts(trip.tripOpts)
                    if (isDateInRange(null, trip.tripOpts.calendarStart, trip.tripOpts.calendarEnd)){
                        setChosenDate(new Date())
                    } else {
                        setChosenDate(trip.tripOpts.calendarStart)
                    }
                } else {
                    setNoTrip(true)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false)
            })
        }
    }, [currentUser, setChosenDate, id])

    //watch trip data from firestore for any change and update trips from globastore
    useEffect(() => {
        if (id){
            const tripRef = getTripRef(id)
            const unsub = onSnapshot(tripRef, (doc) => {
                const data = doc.data()
                if (data && currentUser && (data.users && data.users[currentUser.email])){
                    setTripData(data)
                    document.title = `Palwego - ${data.tripOpts.name}`
    
                    if(trips !== null){
                        const existDataIndex = trips.findIndex((t) => t.id === data.id)
                        const updatedTrip = {
                            id: data.id,
                            tripOpts: data.tripOpts,
                            users: data.users
                        }
                        if (existDataIndex >= 0){
                            trips[existDataIndex] = updatedTrip
                        } else {
                            let newTrips = [...trips, updatedTrip]
                            setTrips(newTrips)
                        }
                    }

                    //construct users object that contains all user emails and displaynames, 
                    //to be used in InviteModal and ScCalendar
                    if(data.users){
                        let users = Object.keys(data.users)
                        if (users.length) {
                            dbGetNameFromTrip(users).then((names) => {
                                setTripUsers(names)
                            })
                        }
                    }
                } else if (data){
                    setTripData(data)
                    document.title = `Palwego - ${data.tripOpts.name}`
                } else {
                    document.title = 'Palwego'
                }
            })
            return () => {
                unsub();
            }
        }
    }, [id, trips, currentUser, setTripData, setTrips])

    if (isLoading){
        return (
            <div className="h-full flex justify-center items-center">
                <div className="h-60">
                    <LoadingSpinner></LoadingSpinner>
                </div>
            </div>
        )
    } else {
        if (redirectModal) {
            return (
                <RedirectModal redirectModal={redirectModal} setRedirectModal={setRedirectModal} />
            )
        } else if (noAccess) {
            return (
                <div className='flex justify-center items-center h-full'>
                    <div className={style.noAccessBox}>
                        <div className={style.errorText}>You do not access to this trip, please request access to view!</div>
                        <Button type="primary" className={style.requestButton}>Request Access</Button>
                    </div>
                </div>
            )
        } else if (noTrip) {
            return (
                <div className='flex justify-center items-center h-full'>
                    <div className={style.noAccessBox}>
                        <div className={style.errorText}>The trip you are trying to access no longer exists</div>
                        <Button type="primary" className={style.requestButton} onClick={() => navigate('/')}>Back to home</Button>
                    </div>
                </div>
            )
        } else {
            return (
                <>
                <PreviewModal previewModal={previewModal} setPreviewModal={setPreviewModal}></PreviewModal>
                <div className={`flex ${isMobile && !sidebarOpen && calendarView === 'listWeek' ? style.dashboardBodyMobile : style.dashboardBody}`}>
                    <Sidebar tripOpts={tripOpts} tripId={id} setIsLoading={setIsLoading}/>
                    {tripOpts && <ScCalendar tripOpts={tripOpts} />}
                </div>
                </>
            )
        }
    }
}

export default Dashboard;
