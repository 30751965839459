import { useState } from 'react';
import styles from './home-main.module.scss'
import { dbCreateTrip } from '../../../firebase/db/trips';
import globaStore from '../../../global/globastore';
import { Button } from 'antd';
import { firebaseAnalytics } from '../../../firebase/analytics';
import { logEvent } from 'firebase/analytics';
import CityConfig from './city-config/city-config';
import { IconCircleMinus, IconCirclePlus } from '@tabler/icons-react';
import { useNavigateToTrip } from '../../../utils/react-utils/navigate';
import authModalStore from '../../auth/modal/auth-modal.store';

const HomeMain = () => {
    const navigateToTrip = useNavigateToTrip()
    const {currentUser} = globaStore()
    const {setAuthModal} = authModalStore()
    const [tripName, setTripName] = useState('')
    const [loadingCreate, setLoadingCreate] = useState(false)
    const [cities, setCities] = useState([
        {
            start: '',
            end: '',
            formattedAddress: '',
            name: '',
            country: '',
            geometry: {},
            validAddress: false,
        }
    ])

    let startDate, endDate

    const onTripNameChange = (e) => {
        setTripName(e.target.value)
    }

    const validatedInputs = () => {
        return tripName && cities.every((city) => {
            return city.validAddress && city.geometry && city.start && city.end
        })
    }

    const onTripCreate = async () => {
        let earliestDate
        let latestDate

        cities.forEach((city) => {
            let sDate = new Date(city.start)
            let eDate = new Date(city.end)

            if (!earliestDate || sDate < earliestDate){
                earliestDate = sDate
                startDate = city.start
            }
            if (!latestDate || eDate > latestDate){
                latestDate = eDate
                endDate = city.end
            }
        })

        try {
            setLoadingCreate(true)
            let tripOpts = {
                name: tripName,
                events: [],
                calendarStart: startDate,
                calendarEnd: endDate,
                cities
            }
            let trip = await dbCreateTrip(currentUser, tripOpts)
            navigateToTrip(trip.id)
            setLoadingCreate(false)
            logEvent(firebaseAnalytics, `analytics_tripName_${tripName}`)
        } catch (err) {
            console.log(err)
        }
    }

    const addCity = () => {
        if (cities.length < 10){            
            setCities([...cities, {
                start: '',
                end: '',
                formattedAddress: '',
                name: '',
                country: '',
                geometry: {},
                validAddress: false
            }])
        } else {
            return
        }
    }

    const removeCity = () => {
        cities.splice(cities.length - 1, 1)
        setCities([...cities])
    }

    return (
        <div className={styles.homeContent}>
            <div className={styles.homeTop}>
                <div className={styles.homeTitleContainer}>
                    <span className={styles.homeTitle + " " + "font-black text-4xl unselectable"}>Plan Better, Discover More, Enjoy Together</span>
                    <span className={styles.homeTitle + " " + "font-extrabold text-3xl unselectable"}>Your Perfect Travel Planner</span>
                </div>
            
                {currentUser ? <div className={styles.homeInputsContainer + ' ' + 'box-shadow-light'}>
                    <input id="trip-name" name="trip-name" type='text' className='sc-input' placeholder='Trip name' onChange={onTripNameChange}></input>
                    {cities.map((city,idx) => (
                        <CityConfig key={idx} idx={idx} cities={cities} setCities={setCities}/> 
                    ))}

                    <div className='flex justify-between'>
                        {cities.length < 10 ? <button
                            className='flex gap-1 text-sm items-center text-primary'
                            onClick={addCity}
                        >
                            <IconCirclePlus />
                            Add City
                        </button>
                        :
                        <div></div>
                        }
                        {cities.length > 1 && 
                            <button
                                className='flex gap-1 text-sm items-center text-danger'
                                onClick={removeCity}
                            >
                                <IconCircleMinus />
                                Remove City
                            </button>
                        }
                    </div>
                    <div className={styles.homeInputsAction}>
                        <Button 
                            type="primary"
                            disabled={!validatedInputs()} 
                            className='h-full w-full rounded-3xl' 
                            onClick={() => onTripCreate()}
                            loading={loadingCreate}
                        >Create</Button>
                    </div>
                </div> 
                : 
                <div className='pb-8'>
                    <Button
                        type="secondary"
                        className='h-full w-full rounded-3xl py-3 px-7 box-shadow-light' 
                        onClick={() => setAuthModal(true)}
                    >
                        <span className='font-semibold text-xl'>
                            Get Started    
                        </span> 
                    </Button>

                </div>
                
                }
            </div>

        </div>
    );
};

export default HomeMain;