import { useForm } from "react-hook-form";
import { useState } from "react";
import { signup } from '../../../firebase/auth'
import styles from "./signup.module.scss";
import authModalStore from "../modal/auth-modal.store";
import GoogleButton from "../google-signin/google-signin";
import { IconExclamationCircle } from "@tabler/icons-react";


const Signup = () => {
    const {
        handleSubmit,
        formState: { errors },
        register,
        watch
    } = useForm();

    const [errorsState, setErrors] = useState({error: false, message: ''})
    const {setAuthModal, setCurrentTab} = authModalStore()

    const onSubmit = async (data) => {
        try {
            await signup(data)
            setAuthModal(false)
            setCurrentTab('signin')
        } catch (err) {
            if (err.code === 'auth/email-already-in-use'){
                setErrors({error: true, message: 'Account with this email already exists'})
            } else {
                setErrors({error: true, message: err.code})
            }
        }
    }
    
    return (
        <>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.formBody}>
                    <input
                        className={styles.formInput}
                        type="email"
                        placeholder="E-mail"
                        autoComplete="off"
                        {...register("email", {
                            required: { value: true, message: "Email is required." },
                            pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Please enter a valid email'}
                        })}
                    />
                    <div className={styles.formInputError}>
                        {errors.email && <span className="flex items-center gap-1"><IconExclamationCircle size={18} /> {errors.email.message}</span>}
                    </div>
                    <div className={styles.nameContainer}>
                        <div className={styles.name}>
                            <input
                                className={styles.formInput}
                                type="text"
                                placeholder="First Name"
                                autoComplete="off"
                                {...register("firstName", {
                                    required: { value: true, message: "First name is required" },
                                })}
                            />

                        </div>
                        <div className={styles.name}>
                            <input
                                className={styles.formInput}
                                type="text"
                                placeholder="Last Name"
                                autoComplete="off"
                                {...register("lastName", {
                                    required: { value: true, message: "Last name is required" },
                                })}
                            />
                        </div>
                    </div>
                    <div className={styles.formInputError}>
                        {(errors.firstName && errors.lastName) ? <span className="flex items-center gap-1"><IconExclamationCircle size={18} /> Name is required</span> :
                        errors.firstName ? <span className="flex items-center gap-1"><IconExclamationCircle size={18} /> {errors.firstName.message}</span> :
                        errors.lastName ? <span className="flex items-center gap-1"><IconExclamationCircle size={18} /> {errors.lastName.message}</span> : null
                        }
                    </div>
                    <input
                        className={styles.formInput}
                        type="password"
                        placeholder="Password"
                        autoComplete="off"
                        {...register("password", {
                            required: { value: true, message: "Password is required." },
                            minLength: { value: 6, message: "Password cannot be less than 6 characters"},
                            maxLength: { value: 30, message: "Password cannot be more than 30 characters"}
                        })}
                    />
                    <div className={styles.formInputError}>
                        {errors.password?.message && <span className="flex items-center gap-1"><IconExclamationCircle size={18} /> {errors.password.message}</span>}
                    </div>

                    <input
                        className={styles.formInput}
                        type="password"
                        placeholder="Confirm Password"
                        autoComplete="off"
                        {...register("confirmPassword", {
                            required: {value: true, message: "Confirm Password is required."},
                            validate: value => value === watch("password", "") || "Passwords do not match"
                        })}
                    />
                    <div className={styles.formInputError}>
                        <span>
                            {errors.confirmPassword?.message && <span className="flex items-center gap-1"><IconExclamationCircle size={18} /> {errors.confirmPassword.message}</span>}
                        </span>
                    </div>
                </div>
                <div className={styles.formFooter}>
                    {errorsState.error && <span className={styles.formAuthError}>{errorsState.message}</span>}
                    <div className={styles.formAction}>
                        <button className={`${styles.primaryButton} ${styles.button}`} type="submit">Sign Up</button>
                    </div>
                </div>
            </form>
            &nbsp;
            <div className={styles.formAction}>
                <GoogleButton />
            </div>
        </>

    )
}

export default Signup