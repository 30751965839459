import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";
import globaStore from "../../global/globastore";
import { dbGetUser } from "../../firebase/db/users";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../components/shared/components/loading-spinner/loading-spinner";

const AdminRoute = ({ children }) => {
    const { currentUser, setIsAdmin, isAdmin } = globaStore();
    const location = useLocation()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (currentUser){
            fetchUser();
        }
    }, [currentUser]);

    const fetchUser = async () => {
        try {
            const user = await dbGetUser(currentUser.email);
            setIsAdmin(user.admin)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    if (loading) {
        return <div className="h-screen flex justify-center items-center">
            <div className="h-60">
                <LoadingSpinner></LoadingSpinner>
            </div>
        </div>
    } 

    if (isAdmin) {
        return children
    } else {
        return <Navigate to={{pathname: "/",  state: { prevPath: location.pathname }}}/>;
    }



};

AdminRoute.propTypes = {
    children: PropTypes.node,
};

export default AdminRoute;