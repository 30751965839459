import {
    setDoc,
    doc,
    getDoc,
    updateDoc,
} from 'firebase/firestore'
import firestore from './firestore';
import { updateDisplayName } from '../auth';

export const dbUserExists = async (email) => {
    try {
        let userRef = doc(firestore, "users", email);
        let dbUser = await getDoc(userRef)
        return dbUser.exists()
    } catch (err) {
        console.log(err.message)
    }
}

export const dbAddUser = async (user, provider) => {
    try {
        setDoc(doc(firestore, 'users', user.email), {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            authProvider: provider,
            trips: [],
        })
    } catch (err) {
        console.log(err.message);
    }
}

export const dbGetUser = async (email) => {
    try {         
        let userRef = doc(firestore, "users", email);
        let dbUser = await getDoc(userRef)
        if (dbUser){
            return dbUser.data()
        } else {
            return null
        }
    } catch (err){
        console.log(err.message)
    }
}

export const dbGetUserRef = async (user) => {
    try {
        if (user && user.email){
            return await doc(firestore, "users", user.email)
        } else {
            return null
        }
    } catch (err){
        console.log(err)
    }
}

export const dbUserRemoveTrip = async (tripId, email) => {
    try {
        let userRef = await doc(firestore, "users", email)
        let {trips} = await dbGetUser(email)

        let idx = trips.indexOf(tripId)
        if (idx >= 0) {
            trips.splice(idx, 1)
            await updateDoc(userRef, {
                trips
            })
        }

        let updated = await getDoc(userRef)
        return updated.data()
    } catch (err){
        console.log(err)
    }
}

export const dbUserAddTrip = async (tripId, email) => {
    try {
        const userRef = doc(firestore, "users", email);
        const userData = await dbGetUser(email)
    
        if(userData && !userData.trips.includes(tripId)) {
            userData.trips.push(tripId)
            await updateDoc(userRef, {
                trips: userData.trips
            })
        }
    } catch (err){
        console.log(err)
    }
}

export const dbGetNameFromTrip = async (emails) => {
    try {
        let users = emails.map(async (email) => {
            let user = await dbGetUser(email)
            if (user) {
                return {
                    email: user.email, 
                    displayName: user.displayName
                }
            } else {
                return {
                    email,
                    displayName: ''
                }
            }
        })
        return Promise.all(users)
    } catch (err) {
        console.log(err)
    }
}

export const dbChangeDisplayName = async (name, email) => {
    try {
        const userRef = doc(firestore, "users", email);
    
        await updateDoc(userRef, {
            displayName: name
        })
        updateDisplayName(name)
        return
    } catch (err) {
        console.log(err)
    }
}