import React, { useEffect, useState } from 'react';
import globaStore from '../../../../global/globastore';
import { Avatar, Tooltip } from 'antd';
import dashboardStore from '../../dashboard-store';
import { MatColor20 } from '../../../../utils/constants/colors';
import { getDatabase, onDisconnect, ref, set } from 'firebase/database';
import firebase from '../../../../firebase/firebase';
import { Spin } from 'antd';

const CurrentView = () => {

    const {tripId, tripData} = dashboardStore()
    const [onlineUsers, setOnlineUsers] = useState([]);
    const {currentUser} = globaStore()


    useEffect(() => {
        if (currentUser && tripId){    
            const id = currentUser.uid
            const rtdb = getDatabase(firebase)
            const onlineStatus = ref(rtdb, `/onlineUsers/${id}`)
            const userStatus = {
                email: currentUser.email,
                displayName: currentUser.displayName,
                online: true,
                tripId: tripId,
            }
            set(onlineStatus, userStatus)
                .catch(err => console.log(err))
            
            onDisconnect(onlineStatus).set({
                email: currentUser.email,
                displayName: currentUser.displayName,
                online: false,
                tripId: tripId,
            })
            .catch(err => console.log(err))
        }

        return () => {
            if (currentUser) {
                const id = currentUser.uid;
                const rtdb = getDatabase(firebase)
                const onlineStatus = ref(rtdb, `/onlineUsers/${id}`);
                set(onlineStatus, {
                    email: currentUser.email,
                    displayName: currentUser.displayName,
                    online: false,
                    tripId: tripId,
                })
                .catch(err => console.log(err))
            }
        }
    }, [currentUser, tripId])

    useEffect(() => {
        if (tripData){
            setOnlineUsers(tripData.currentUsers)
        }
    }, [tripData])

    return (
        <>
        {(onlineUsers.length > 0) && 
            <div className='flex flex-col pb-3 w-full gap-2 items-start'>
                <span className='font-semibold'>Online:</span>
                <Avatar.Group max={{count: 5}}>
                    {onlineUsers.length ? onlineUsers.map((user, i) => (
                        <Tooltip title={user.displayName} key={user.email} placement='top'>
                            <Avatar size='large' key={user.email} style={{backgroundColor: MatColor20[i % MatColor20.length]}}>
                                {user.displayName[0]}
                            </Avatar>
                        </Tooltip>
                    )) : 
                    <Spin size='large'/>
                    }
                </Avatar.Group>
            </div>
        }
        </>
    )
}

export default CurrentView
