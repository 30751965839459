import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { firebaseAuth } from './firebase/auth'
import { onAuthStateChanged } from "firebase/auth";
import { dbGetUser } from "./firebase/db/users";
import LoadingSpinner from "./components/shared/components/loading-spinner/loading-spinner";
import { dbGetTrips } from "./firebase/db/trips";
import globaStore from "./global/globastore";
import { RouterProvider } from "react-router-dom";
import {navigationRouter} from "./utils/router/router";
import { ConfigProvider } from "antd";
import ReactGA from "react-ga4"
import { getDatabase, onDisconnect, ref, serverTimestamp, set } from "firebase/database";
import firebase from "./firebase/firebase";

function App() {
    ReactGA.initialize("G-C90Y7KKWP9");

    const [pending, setPending] = useState(true);
    const {currentUser, setCurrentUser, setTrips} = globaStore()

    useLayoutEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, user => {
            if (!user || !user.emailVerified) setPending(false)
            setCurrentUser(user)
        });
        return unsubscribe
    }, []);

    // useEffect(() => {
    //     if (currentUser) {
    //         const id = currentUser.uid
    //         const email = currentUser.email
    //         const rtdb = getDatabase(firebase)
    //         const onlineStatus = ref(rtdb, `/onlineUsers/${id}`);
    //         const userStatus = {
    //             email: email,
    //             online: true,
    //             lastSeen: serverTimestamp()
    //         }
    //         set(onlineStatus, userStatus)
    //             .then(() => console.log('Online status set to true'))
    //             .catch(err => console.log(err))

    //         onDisconnect(onlineStatus).set({
    //             email: email,
    //             online: false,
    //             lastSeen: serverTimestamp()
    //         })
    //             .then(() => console.log("Online status set to false"))
    //             .catch(err => console.log(err))
    //     }

    //     return () => {
    //         if (currentUser) {
    //             const email = currentUser.email;
    //             const id = currentUser.uid;
    //             const rtdb = getDatabase(firebase)
    //             const onlineStatus = ref(rtdb, `/onlineUsers/${id}`);
    //             set(onlineStatus, {
    //                 email: email,
    //                 online: false,
    //                 lastSeen: serverTimestamp()
    //             })
    //             .then(() => console.log("clean up"))
    //             .catch(err => console.log(err))
    //         }
    //     };
    // }, [currentUser])

    useMemo(() => {
        if (currentUser) {
            dbGetUser(currentUser.email)
            .then((user) => {
                if (user){
                    dbGetTrips(user.trips).then((trips) => {
                        if (trips){
                            setTrips(trips)
                        } else {
                            setTrips([])
                        }
                    })
                } 
            })
            .finally(() => {
                setPending(false)
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }, [currentUser])

    if(pending){
        return (
            <div className="h-screen flex justify-center items-center">
                <div className="h-60">
                    <LoadingSpinner></LoadingSpinner>
                </div>
            </div>
        )
    } else {
        return (
            <ConfigProvider
                theme={{
                    hashed:false,
                    token: {},
                    components: {
                        Menu: {
                            itemSelectedColor: '#00c8ff',
                            itemActiveBg: '#00c8ff'
                        }
                    }
                }}
            >
                <div className="App">
                    <RouterProvider router={navigationRouter} />
                </div>
            </ConfigProvider>
        )
    }
}
export default App;
