import { notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
const GlobaContext = React.createContext();

export const useGlobaContext = () => {
    return useContext(GlobaContext)
}

export const GlobaProvider = ({children}) => {

    const [notificationApi, notificationContextHolder] = notification.useNotification();
    const [width, setWidth] = useState(window.innerWidth);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    useEffect(() => {
        setIsSmallScreen(width <= 768)
    }, [width])


    const toastNotification = (type, config) => {
        let defaultConfig = {
            placement: 'bottom',
            duration: 3,
        }
        let cfg = Object.assign(defaultConfig, config)
        notificationApi[type](cfg);
    }

    const value = {
        toastNotification,
        isSmallScreen
    }

    return (
        <>
            <GlobaContext.Provider value={value}>
                {notificationContextHolder}
                {children}
            </GlobaContext.Provider>
        </>
    );

}

