import { Modal } from 'antd';
import Signin from "../signin/signin";
import Signup from "../signup/signup";
import styles from './auth-modal.module.scss';
import authModalStore from "./auth-modal.store";
import ResetPassword from "../reset-password/reset-password";
import globaStore from '../../../global/globastore';
import { useEffect } from 'react';

const AuthModal = ({mask=true, maskClosable=true}) => {

    const {currentTab, setCurrentTab, authModal, setAuthModal} = authModalStore()
    const {currentUser} = globaStore();

    const changeTab = () => {
        if (currentTab === 'signin'){
            setCurrentTab('signup')
        } else {
            setCurrentTab('signin')
        }
    }

    const onModalCancel = () => {
        setAuthModal(false)
        setCurrentTab('signin')
    }

    useEffect(() => {
        if (currentUser) {
            setAuthModal(false)
        }
    }, [currentUser])
    
    return (
        <Modal 
            classNames={({
                wrap: styles.modalWrap,
                content: styles.modalContent,
            })}
            open={authModal} 
            footer={null}
            closeIcon={false}
            mask={mask}
            maskClosable={maskClosable}
            destroyOnClose={true}
            onCancel={onModalCancel}
        >
            <div className={styles.modalHeader}>
                {(currentTab === 'signup' || currentTab === 'signin') && 
                    <>
                    <input 
                        className={styles.checkbox} 
                        type="checkbox" 
                        id="reg-log" 
                        checked={currentTab === 'signup'}
                        onChange={changeTab}
                        />
                    <label className={styles.formButtonContainer} htmlFor="reg-log">
                        <div className={styles.titleButton + " " + (currentTab === 'signin'? styles.selectedTab : '')}>Log In </div>
                        <div className={styles.titleButton + " " + (currentTab === 'signup'? styles.selectedTab : '')}>Sign Up</div>
                    </label>
                    </>
                }
                {(currentTab === 'reset') && 
                    <>Reset Password</>
                }
            </div>
            <div className={styles.modalBody}>
                {(currentTab === 'signin') && <Signin />} 
                {(currentTab === 'signup') && <Signup />}
                {(currentTab === 'reset') && <ResetPassword />}
            </div>
        </Modal>
    )

}

export default AuthModal;