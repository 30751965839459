import styles from './trips.module.scss'
import globaStore from '../../global/globastore';
import tripsStore from './trips.store'
import { formatDateMMMDDYY } from '../../utils/dateFns/date-fns';
import { useEffect } from 'react';
import InviteModal from '../dashboard/sidebar/invite-modal/invite-modal';
import dashboardStore from '../dashboard/dashboard-store';
import RemoveSelfModal from './remove-self-modal/remove-self-modal';
import { IconTrash, IconUsers } from '@tabler/icons-react';
import { useNavigateToTrip } from '../../utils/react-utils/navigate';

const Trips = () => {
    const navigateToTrip = useNavigateToTrip();

    const {trips} = globaStore()
    const {upcomingTrips, setUpcomingTrips, pastTrips, setPastTrips, selectedTrip, setSelectedTrip, setRemoveSelfModal} = tripsStore()
    const {setInviteModal} = dashboardStore()

    document.title = `Palwego - Trips`

    useEffect(() => {
        if (trips && trips.length){
            let today = new Date()
            let upcoming = trips
                .filter((t) => new Date(t.tripOpts.calendarStart) >= today)
                .sort((a,b) => new Date(a.tripOpts.calendarStart) - new Date(b.tripOpts.calendarStart))
            setUpcomingTrips(upcoming)

            let past = trips
                .filter((t) => new Date(t.tripOpts.calendarStart) < today)
                .sort((a,b) => new Date(a.tripOpts.calendarStart) - new Date(b.tripOpts.calendarStart))
            setPastTrips(past)
        }
    }, [trips])

    const onInviteClick = (trip, event) => {
        event.preventDefault()
        event.stopPropagation()
        setSelectedTrip(trip)
        setInviteModal(true)
    }

    const onLeaveTripClick = (trip, event) => {
        event.preventDefault()
        event.stopPropagation()
        setSelectedTrip(trip)
        setRemoveSelfModal(true)
    }  

    return (
        <>
        <div className='h-screen overflow-hidden'>
            {(trips) ? (
            <div className={styles.pageBody}>
                {(upcomingTrips.length) ? (
                    <div className={styles.sectionContainer}>
                        <span className={styles.sectionTitle}>Upcoming Trips</span>
                        {upcomingTrips.map((trip, idx) => (
                            <div key={idx} className={styles.trip} onClick={() => navigateToTrip(trip.id)}>
                                <div className={styles.tripDetails}>
                                    <span className={styles.tripName + " " + "text-xl"}>{trip.tripOpts.name}</span>
                                    <span className="text-xs">{formatDateMMMDDYY(trip.tripOpts.calendarStart)} - {formatDateMMMDDYY(trip.tripOpts.calendarEnd)}</span>
                                </div>
                                <div className={styles.tripActions}>
                                    <button onClick={($event) => onInviteClick(trip, $event)}>
                                        <IconUsers size={22} />
                                    </button>
                                    <button onClick={($event) => onLeaveTripClick(trip, $event)}>
                                        <IconTrash size={22}/>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : <></>}

                {(pastTrips.length) ? (
                    <div className={styles.sectionContainer}>
                        <span className={styles.sectionTitle}>Past Trips</span>
                        {pastTrips.map((trip, idx) => (
                            <div key={idx} className={styles.trip} onClick={() => navigateToTrip(trip.id)}>
                                <div className={styles.tripDetails}>
                                    <span className={styles.tripName + " " + "text-xl"}>{trip.tripOpts.name}</span>
                                    <span className="text-xs">{formatDateMMMDDYY(trip.tripOpts.calendarStart)} - {formatDateMMMDDYY(trip.tripOpts.calendarEnd)}</span>
                                </div>
                                <div className={styles.tripActions}>
                                    <button onClick={($event) => onInviteClick(trip, $event)}>
                                        <IconUsers size={22} />
                                    </button>
                                    <button onClick={($event) => onLeaveTripClick(trip, $event)}>
                                        <IconTrash size={22}/>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : <></>}

                {(!trips.length) && (
                    <div className={styles.sectionContainer}>
                        <span className={styles.sectionTitle}>No trips created</span>
                    </div>
                )}
            </div>
            ) : (
                <div className={styles.pageBody}>
                    <div className={styles.sectionContainer}>
                        <span className={styles.sectionTitle}>No trips created</span>
                    </div>
                </div>
            )}
        </div>
        {(selectedTrip) && <InviteModal tripId={selectedTrip.id} tripOpts={selectedTrip.tripOpts} tripUsers={selectedTrip.users}/>}
        {(selectedTrip) && <RemoveSelfModal tripId={selectedTrip.id} tripOpts={selectedTrip.tripOpts}/>}
        </>
    )
}

export default Trips