import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import style from "./redirect.module.scss";
import globaStore from '../../../global/globastore';
import AuthModal from '../modal/auth-modal';
import authModalStore from '../modal/auth-modal.store';

const Redirect = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { currentUser } = globaStore();
    const {setAuthModal} = authModalStore()

    useEffect(() => {
        setAuthModal(true)
        return () => {
            setAuthModal(false)
        }
    }, [])

    useEffect(() => {
        if(currentUser){
            if (location.state?.prevPath){
                navigate(location.state.prevPath)
            } else {
                navigate('/')
            }
        }
    }, [currentUser])

    return (
        <div className={style.redirectPage}>
            <div className='flex justify-center items-center h-screen'>
                <AuthModal maskClosable={false} mask={false}></AuthModal>
            </div>
        </div>
    )
}

export default Redirect