import { useEffect, useRef, useState } from "react"
import useGoogleMapsApi from "../../../../utils/google/useGoogleMapsApi"
import Tagify from '@yaireo/tagify'
import styles from './city-tags.module.scss'
import { Button } from "antd"
import { IconCirclePlus } from "@tabler/icons-react"

const CityTags = ({cities, setCities, readonly}) => {
    const [adding, setAdding] = useState(false)
    const googleMapsApi = useGoogleMapsApi()
    let autocompleteEl = useRef(null)
    let placesAutocomplete = useRef(null)
    const tagify = useRef()
    const tagRef = useRef()

    useEffect(() => {
        if (!tagify.current) {
            tagify.current = new Tagify(
                tagRef.current,
                {
                    maxTags: 10,
                    userInput: false,
                    readonly,
                }
            )
            if (cities.length) {
                tagify.current.addTags(cities)
            }
        }
        if (!readonly){
            tagify.current.on('change', (event) => setCities(JSON.parse(event.detail.value.split(','))))
        }

    }, [tagify])

    useEffect(() => {
        if(adding && !readonly){
            placesAutocomplete.current = new googleMapsApi.places.Autocomplete(
                autocompleteEl.current,
                {
                    types: ['(cities)'],
                    fields: ["formatted_address", "name", "address_components"],
                }            
            );
            placesAutocomplete.current.addListener('place_changed', onPlaceChanged);
        }
    }, [adding])

    const onPlaceChanged = () => {
        const place = placesAutocomplete.current.getPlace()
        tagify.current.addTags(place.name)
        setAdding(false)
    }

    return (
        <div className={styles.container}>
            <span className="text-lg font-semibold">Cities: </span>
            <div className={cities.length ? 'flex':'hidden'}>
                <input
                    ref={tagRef}
                    className={styles.cityTags}
                />
            </div>
            {adding && <input
                type="text"
                ref={autocompleteEl}
                placeholder='Add City'
                className={styles.addCity + " sc-input"}
                id="city-tags"
                name="city-tags"
            />
            }      
            {(!adding && !readonly) && <button className="text-primary" onClick={() => setAdding(true)}><IconCirclePlus></IconCirclePlus></button>}

        </div>

    )
}

export default CityTags