import { Button, Modal } from "antd"
import styles from './remove-self-modal.module.scss'
import tripsStore from "../trips.store";
import { useState } from "react";
import { dbTripDelete, dbGetTrips, dbRemoveUserFromTrip, dbGetTrip } from "../../../firebase/db/trips";
import globaStore from "../../../global/globastore";
import { dbGetUser, dbUserRemoveTrip } from "../../../firebase/db/users";

const RemoveSelfModal = ({tripId, tripOpts}) => {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const {removeSelfModal, setRemoveSelfModal} = tripsStore()
    const {currentUser, setTrips} = globaStore()

    const leaveTrip = async () => {
        setConfirmLoading(true)
        const {users} = await dbGetTrip(tripId)
        console.log(users)
        if (users[currentUser.email]){
            if (Object.keys(users).length === 1){
                await dbTripDelete(tripId)
            } else {
                await dbRemoveUserFromTrip(tripId, currentUser.email)
            }
        }
        const updatedUser = await dbUserRemoveTrip(tripId, currentUser.email)
        const trips = await dbGetTrips(updatedUser.trips)

        setTrips(trips)
        setConfirmLoading(false)
        setRemoveSelfModal(false)
    }

    return (
        <Modal
            classNames={({
                wrap: styles.modalWrap,
                content: styles.modalContent,
            })}
            open={removeSelfModal} 
            closeIcon={false}
            onCancel={() => setRemoveSelfModal(false)}
            title="Confirm"
            footer={[
                <Button
                    key="cancel"
                    onClick={() => setRemoveSelfModal(false)}
                >Cancel
                </Button>,
                <Button
                    className={styles.submitButton}
                    danger
                    key="submit"
                    type="primary"
                    loading={confirmLoading}
                    onClick={leaveTrip}
                >Leave Trip</Button>
            ]}
        >
            Are you sure you want to leave {tripOpts.name} ?
        </Modal>
    )
}

export default RemoveSelfModal