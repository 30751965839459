import React, { useState } from 'react';
import styles from "./sidebar.module.scss";
import CurrentView from './current-view/current-view';
import { Button, Menu } from 'antd';
import InviteModal from './invite-modal/invite-modal';
import dashboardStore from '../dashboard-store';
import navbarStore from '../../navbar/navbar.store';
import globaStore from '../../../global/globastore';
import DateConfig from './date-config/date-config';
import ViewToggler from './view-toggler/view-toggler';
import TripNotes from './trip-notes/trip-notes';
import { IconCopy, IconSearch, IconSend } from '@tabler/icons-react';
import { isMobile } from 'react-device-detect';
import TripPrice from './trip-price/trip-price';
import ShareTrip from './share-trip/share-trip';
import CopyTripModal from './copy-trip-modal/copy-trip-modal';
import { useLocation, useNavigate } from 'react-router-dom';

function getItem(key, icon) {
    return {
        key,
        icon,
    }
}

export default function Sidebar({tripOpts, tripId, setIsLoading}) {
    const navigate = useNavigate();
    const location = useLocation()
    const {setInviteModal, inviteModal, isVisitor, scCalendar} = dashboardStore()
    const {sidebarOpen} = navbarStore()
    const {currentUser} = globaStore()
    const [copyTripModal, setCopyTripModal] = useState(false)

    let items = []
    let lastItems = []

    const onCopyTripClick = () => {
        if (currentUser){
            setCopyTripModal(true)
        } else {
            navigate('/redirect', {state: { prevPath: location.pathname }})
        }
    }

    if (isVisitor){
        items = [
            <ViewToggler />,
            <DateConfig />,
        ]
        lastItems = [
                <Button 
                    type='primary'
                    className={styles.inviteButton}
                    onClick={onCopyTripClick}    
                >
                    <IconCopy />
                    Copy Trip
                </Button>  
        ]
    } else if (currentUser && currentUser.emailVerified){
        items = [
            <button className={styles.searchEventButton} onClick={() => scCalendar.openEventFinder()}>
                <IconSearch></IconSearch>
                <span>Search Event</span>
            </button>,
            <ViewToggler />,
            <DateConfig />,
            <TripPrice />,
            <TripNotes />,
            <ShareTrip />,
        ]

        lastItems = [
            <CurrentView />,
            <Button 
                type='primary'
                className={styles.inviteButton}
                onClick={() => setInviteModal(true)}    
            >
                <IconSend />
                Invite
            </Button>  
            //Invite button needs to be last child so it is at the bottom of sidebar
        ]
    } 

    return (
        <div className={`${styles.menuWrapper} ${sidebarOpen ? 'flex' : 'hidden'} ${isMobile ? 'min-w-full ps-4 pe-4' : styles.widescreenSidebar}`}>
            <div className={`${styles.container} `}>
                <div className={styles.topContainer}>
                    {items.map((item, index) => (
                        <React.Fragment key={index}>
                            {item}
                        </React.Fragment>
                    ))}                </div>
                <div className='flex flex-col w-full justify-start'>
                    {lastItems.map((item, index) => (
                        <React.Fragment key={index}>
                            {item}
                        </React.Fragment>
                    ))}                  
                </div>
            </div>
            {inviteModal && <InviteModal tripOpts={tripOpts} tripId={tripId}/>}
            {copyTripModal && <CopyTripModal copyTripModal={copyTripModal} setCopyTripModal={setCopyTripModal} setIsLoading={setIsLoading}/>}
        </div>

    )
}
