import { useEffect, useState } from "react"
import styles from './trip.module.scss'
import { useNavigateToTrip } from "../../../../utils/react-utils/navigate"
import { formatDateMMMDDYY } from "../../../../utils/dateFns/date-fns";
import { createClient } from 'pexels';
import tripThumbnail from '../../../../assets/background/trip.jpg'
import { dbTripUpdateField } from "../../../../firebase/db/trips";

const Trip = ({trip}) => {
    const client = createClient(process.env.REACT_APP_PEXELS);
    const navigateToTrip = useNavigateToTrip();
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        if (trip.thumbnail){
            setImageUrl(trip.thumbnail)
        } else if (trip.tripOpts.cities && trip.tripOpts.cities.length && client){
            let options = {
                query: `${trip.tripOpts.cities[0].name} building landmarks`.replace(/,/g, ''),
                per_page: 1,
                orientation: 'square',
            }

            client.photos.search(options).then(res => {
                setImageUrl(res.photos[0].src.medium)
                dbTripUpdateField(trip.id, {
                    thumbnail: res.photos[0].src.medium
                })
            })
            .catch(err => console.error('Error fetching images:', err)) 

        } else {
            setImageUrl(tripThumbnail)
        }
    }, [trip, client])

    return (
        <div className={styles.trip} onClick={() => navigateToTrip(trip.id)}>
            <div className={styles.thumbnailContainer}>
                <img src={imageUrl} className={styles.thumbnail}></img>
            </div>
            <div className={styles.descriptions}>
                <span className={styles.tripName + " " + "text-xl"}>{trip.tripOpts.name}</span>
                <span className="text-sm">{formatDateMMMDDYY(trip.tripOpts.calendarStart)} - {formatDateMMMDDYY(trip.tripOpts.calendarEnd)}</span>
            </div>
        </div>

    )
}

export default Trip