import { useEffect, useState } from "react";
import dashboardStore from "../../dashboard-store";
import style from './trip-price.module.scss';
import { IconArrowsMaximize } from "@tabler/icons-react";
import TripPriceDetails from "./trip-price-details/trip-price-details";
import { formatToCurrency } from "../../../../utils/currency/currency";
import { Tooltip } from "antd";

const TripPrice = () => {
    const {tripData} = dashboardStore()
    const [tripPrice, setTripPrice] = useState(0)
    const [tripPricePP, setTripPricePP] = useState([])
    const [priceModalOpen, setPriceModalOpen] = useState(false)

    useEffect(() => {
        if (tripData){
            setTripPrice(tripData.tripOpts.tripPrice)
            setTripPricePP(tripData.tripOpts.tripPricePP)
        }
    }, [tripData])


    return (
        <>
        <div className={style.tripPriceContainer}>
            <div className={style.tripPriceOverview}>
                <span>Cost:</span>
                <span>{(tripPricePP && tripPricePP.length > 0) ? formatToCurrency(tripPrice) : 0}</span>
            </div>
            {(tripPrice > 0 && tripPricePP && tripPricePP.length > 0) ? 
                <div className={style.tripPriceOpenButton} onClick={() => setPriceModalOpen(true)}>
                    <IconArrowsMaximize size={18}/>
                </div>
                : 
                <div className={`${style.tripPriceOpenButton} btn-disabled`}>
                    <Tooltip title="Add cost to events to view details">
                        <IconArrowsMaximize size={18}/>
                    </Tooltip>
                </div>            
            }
        </div>
        {priceModalOpen && <TripPriceDetails setModalOpen={setPriceModalOpen} modalOpen={priceModalOpen} tripPricePP={tripPricePP} />}
        </>
    )
}

export default TripPrice
