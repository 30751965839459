import { Button, Modal } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";

const PreviewModal = ({previewModal, setPreviewModal}) => {
    const navigate = useNavigate()
    const location = useLocation()

    const onLoginClick = () => {
        navigate('/redirect', {state: { prevPath: location.pathname }})
    }

    return (
        <Modal
            open={previewModal} 
            closeIcon={false}
            onCancel={() => setPreviewModal(false)}
            title="Preview Mode"
            maskClosable={false}
            footer={[
                <Button type="primary" key='primary-action' onClick={onLoginClick}>
                    Log In
                </Button>,
                <Button type="default" key='secondary-action' onClick={() => setPreviewModal(false)}>
                    Continue
                </Button>,
            ]}
        >
        This trip is created in preview mode, <mark>it will be deleted after 24 hours</mark>. Please sign in or sign up to save the trip to your account. 
        </Modal>
    )
}

export default PreviewModal