import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";
import globaStore from "../../global/globastore";

const PrivateRoute = ({ children }) => {
    const { currentUser } = globaStore();
    const location = useLocation()
    if (currentUser) {
        return children;
    }

    return <Navigate to={{pathname: "/redirect",  state: { prevPath: location.pathname }}}/>;
};

PrivateRoute.propTypes = {
    children: PropTypes.node,
};

export default PrivateRoute;