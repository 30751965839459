import { dbRemoveUserFromTrip } from "../../../../../firebase/db/trips";
import styles from "./remove-button.module.scss";
import { Button, Popconfirm } from 'antd';
import { useGlobaContext } from "../../../../../global/globa-provider";

const RemoveButton = ({email, tripId, onRemoveUser}) => {
    
    const {toastNotification} = useGlobaContext();

    const handleRemoveUser = () => {
        dbRemoveUserFromTrip(tripId, email)
            .then((res) => {
                onRemoveUser(email)
            })
            .then(() => {
                toastNotification('success', {message: "User removed!"})
            })
            .catch(err => {
                console.log(err)
                toastNotification('error', {message: "Failed to remove user!"})
            })
    }

    return (
        <Popconfirm
            placement="top"
            title="Are you sure to remove this user?"
            okText="Yes"
            cancelText="No"
            onConfirm={handleRemoveUser}
        >
            <button className={styles.button}>Remove</button>
            {/* <Button danger>Remove</Button> */}
        </Popconfirm>
    )
}

export default RemoveButton;